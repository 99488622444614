<template>
  <div class="tags-container">
    <template v-if="Array.isArray(row[column.prop])">
      <template v-if="row[column.prop].length === 0">
        <el-tag class="default-tag">{{ t('any') }}</el-tag>
      </template>
      <template v-else v-for="(tag, index) in row[column.prop]">
        <el-tag
          v-if="tag.includes('true')"
          :class="(tag.type || 'default') + '-tag'"
          :key="index">
          {{ tag.hasOwnProperty('value') ? getLabel(t(tag.value)) : getLabel(t(tag)) }}
        </el-tag>
      </template>
    </template>
  </div>
</template>

<script>
  import { Tag } from "element-ui";

  export default {
    name: 'status-formatter',
    components: {
      'el-tag': Tag
    },
    props: {
      row: {
        type: Object,
        default: () => {}
      },
      column: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      getLabel: (value) => {
        return value.split(':')[0]
      }
    }
  }
</script>

<style scoped lang=scss>
  .more-tags {
    padding: 0.2em 0.5em;
  }
  .tags-container {
    text-align: center;
  }
  .default-tag {
    background-color: #cfcfcf;
    border-color: #a7a7a8;
    color: #444444;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .nowrap-tag {
    white-space: nowrap;
    overflow: hidden;
  }
</style>