<template>
  <div class="filter-label-container" 	v-if="currentFilter.length > 0">		
		<div class="right-filter-side">
			<button
				v-if="currentFilter.length > 0"
				class="btn-round btn-md clear-tags"
			 	@click="onClearAll"
    	>
    		{{t('Clear all filters')}}
    	</button>	
		</div>				
		<div class="left-filter-side">
			<el-tag
				class="filter-tag"
    		v-for="item in currentFilter"
    		:key="item.label"
				:role="item.role"
    		:type="item.type"
    		closable
    		@close="onCloseTag"
    	>
    			{{ item.label }}
    	</el-tag>	
		</div>
	
	</div>	
</template>

<script>
import { Tag, Button} from 'element-ui';

export default {
	name: 'filter-labels-component',
	components: {
		'el-tag': Tag,
		'el-button': Button,
	},
  props: {
    currentFilter : {
      type: Array
    },
		close: {
			type: Function
		},
		clearAll: {
			type: Function
		}
	},
	methods: {
		onCloseTag(event) {
			this.$emit('close', event)
		},
		onClearAll(event) {
			this.$emit('clear-all', event)
		}
	
  },
	
}
</script>

<style lang="scss">

	.filter-label-container {
		display: flex;
		flex-direction: row-reverse;
		border: 1px solid #383B57;
    background-color: #383B57;
    padding: 10px 20px 4px;
	}

	.white-content .filter-label-container {
		border: 1px solid #E8E8ED;
    background-color: #F5F5FA;
	}

	.left-filter-side {
    display: inline-block;
	}


	.tags-label-container {
    display: flex;
    flex-direction: row-reverse;
	}

	.filter-tag {
		font-size: 14px;
		color: white;
    border-color: #D7D8DD;
		background-color: #383B57;
		padding: 8px 12px 8px 24px;
  	height: 32px;
	}

	.white-content .filter-tag {
		color: #3f4261;
    border-color: #3f4261;
		background-color:white;
	}

	.clear-tags {
		margin: 0px 20px;
		font-size: 14px;
		background-color: #3f4261;
		color: #FF6237;
    border-color: #FF6237;
		height: 32px;
		min-width: 140px;
	}

	.white-content .clear-tags {
		background-color: white;
		color: #FF6237;
    border-color: #FF6237;
	}

	.clear-tags:hover {
		background-color: #FF6237;
		color: white;
    border-color: white;
	}


	.el-tag .el-tag__close {
		color: #3f4261;
    border-color: #3f4261;	
		margin-left: 8px;
	}

 
</style>