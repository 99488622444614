<template>
  <div>
    <page-title-card
      :page-title="t('Services Status')"
    />
    <b-card class="card-user" no-body>
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-three"></div>
        <div class="block block-four"></div>
      </div>

      <b-card-body style="margin-top: -30px; min-height: 75px">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <h5 class="title">
                  <i class="mr-2"><svgicon class="icon" icon="item-status" /></i
                  >{{ t("Services Status") }}
                </h5>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8">
              <b-container fluid style="max-width: 2560px;  margin: 2em;" >
                  <b-row no-gutters>
                      <b-col class="pt-2">
                          {{t('Enable or disable the service that will be globally enabled for the product') + ":"}}
                      </b-col>
                  </b-row>
            </b-container>
            </b-col>
             <b-col cols="12">
                <b-container fluid style="margin-left: 2em">
                  <b-row no-gutters>
                    <b-col :sm="6" :md="6" :xl="6" key="Blocked">
                      <b-container fluid style="padding: 0px">
                        <b-row no-gutters>
                          <b-col
                            cols="8"
                            class="form-group has-label pt-2"
                            align-self="center"
                          >
                            <h5
                              class="mx-auto my-auto"
                              style="white-space: nowrap"
                            >
                              {{ t('Blocked categories') }}
                            </h5>
                          </b-col>
                          <b-col cols="3" align-self="center">
                            <n-switch
                              v-model="service_status.content_filter.content_filtering"
                              @input="handleChangeServiceStatus($event, {'id': 'parental_control', 'service': 'enable_category_filtering'})"
                              :disabled="!service_status.content_filter.global"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/rs/service-configuration' }]"
                            />
                          </b-col>
                        </b-row>
                              <b-row no-gutters>
                          <b-col
                            cols="8"
                            class="form-group has-label pt-2"
                            align-self="center"
                          >
                            <h5
                              class="mx-auto my-auto"
                              style="white-space: nowrap"
                            >
                              {{ t('safeSearch') }}
                            </h5>
                          </b-col>
                          <b-col cols="3" align-self="center">
                            <n-switch
                              v-model="service_status.content_filter.safe_search"
                              service="safeSearch"
                              @input="handleChangeServiceStatus($event,  {'id': 'parental_control', 'service': 'enable_safe_search'})"
                              :disabled="!service_status.content_filter.global"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/rs/service-configuration' }]"
                            />
                          </b-col>
                        </b-row>
                              <b-row no-gutters>
                          <b-col
                            cols="8"
                            class="form-group has-label pt-2"
                            align-self="center"
                          >
                            <h5
                              class="mx-auto my-auto"
                              style="white-space: nowrap"
                            >
                              {{ t('QuietTime') }}
                            </h5>
                          </b-col>
                          <b-col cols="3" align-self="center">
                            <n-switch
                              v-model="service_status.content_filter.quiet_time"
                              service="quietTime"
                              @input="handleChangeServiceStatus($event,  {'id': 'parental_control', 'service':'enable_quiet_time'})"
                              :disabled="!service_status.content_filter.global"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/rs/service-configuration' }]"
                            />
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col :sm="6" :md="6" :xl="6" key="Malware">
                      <b-container fluid style="padding: 0px">
                        <b-row no-gutters>
                          <b-col
                            cols="8"
                            class="form-group has-label pt-2"
                            align-self="center"
                          >
                            <h5
                              class="mx-auto my-auto"
                              style="white-space: nowrap"
                            >
                              {{ t('Malware') }}
                            </h5>
                          </b-col>
                          <b-col cols="3" align-self="center">
                            <n-switch
                              v-model="service_status.malware.status"
                              service="Malware"
                              :disabled="!service_status.malware.global"
                              @input="handleChangeServiceStatus($event,  {'id': 'threat_protection', 'service':'enable_anti_malware'})"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/rs/service-configuration' }]"
                            />
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
        <template v-slot:header>
           <b-row>
              <b-col cols="12">
                <h5 class="title">
                  <i class="mr-2 el-icon-takeaway-box"></i
                  >{{ t("Router Services") }}
                </h5>
              </b-col>
            </b-row>
        </template>

        <b-card-body style="margin-top: -30px">
          <b-row>
            <b-col cols="8">
              <b-container fluid style="max-width: 2560px; margin: 2em" >
                <b-row no-gutters>
                  <b-col class="pt-2">
                    {{t('Enable or disable the service that will apply to all the routers deployed') + ":"}}
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" >
              <b-container fluid style="margin-left: 2em">
                <b-row>
                   <b-col cols="6" :sm="6" :md="6" :xl="6" key="Lan">
                     <b-row no-gutters>
                        <b-col cols="8" class="has-label pt-2">
                         <h5  style="white-space: nowrap">
                           {{ t('LAN Protection') }}
                         </h5>
                        </b-col>
                        <b-col cols="3">
                          <n-switch
                             v-model="lan_protection.enabled"
                             service="LAN Protection"
                             @input="handleChangeLanProtection($event, 'enabled')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                           />

                        </b-col>
                     </b-row>
                     <b-row v-if="lan_protection.enabled">
                      <b-container>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Block Shell Access') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                           <n-switch
                             v-model="lan_protection.default_remote_shell"
                             service="Shell Access"
                             :disabled="!lan_protection.enabled"
                             @input="handleChangeLanProtection($event, 'default_remote_shell')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                           />
                          </b-col>
                        </b-row>
                        <b-row no-gutters v-if="false">
                          <!-- Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1 -->
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Weak Telnet Password') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                            <n-switch
                              v-model="lan_protection.telnet_credentials"
                              service="telnet password"
                              :disabled="!lan_protection.enabled"
                              @input="handleChangeLanProtection($event, 'telnet_credentials')"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                            />
                          </b-col>
                        </b-row>
                        <b-row no-gutters v-if="false">
                          <!-- Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1 -->
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em">
                              {{ t('Brute Force Attack & Port Scan') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                            <n-switch
                              v-model="lan_protection.device_scan"
                              service="Port Scan"
                              :disabled="!lan_protection.enabled"
                              @input="handleChangeLanProtection($event, 'device_scan')"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                            />
                          </b-col>
                        </b-row>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Limit Access to other devices') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                           <n-switch
                             v-model="lan_protection.device_access"
                             service="Limit accesss"
                             :disabled="!lan_protection.enabled"
                             @input="handleChangeLanProtection($event, 'device_access')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                           />
                          </b-col>
                        </b-row>
                      </b-container>
                     </b-row>
                     <b-row v-else>
                      <b-container>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Block Shell Access') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                           <n-switch
                             service="Shell Access"
                             :disabled="!lan_protection.enabled"
                             @input="handleChangeLanProtection($event, 'default_remote_shell')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                           />
                          </b-col>
                        </b-row>
                        <b-row no-gutters v-if="false">
                          <!-- Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1 -->
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Weak Telnet Password') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                            <n-switch
                              service="telnet password"
                              :disabled="!lan_protection.enabled"
                              @input="handleChangeLanProtection($event, 'telnet_credentials')"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                            />
                          </b-col>
                        </b-row>
                        <b-row no-gutters v-if="false">
                          <!-- Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1 -->
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em">
                              {{ t('Brute Force Attack & Port Scan') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                            <n-switch
                              service="Port Scan"
                              :disabled="!lan_protection.enabled"
                              @input="handleChangeLanProtection($event, 'device_scan')"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                            />
                          </b-col>
                        </b-row>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Limit Access to other devices') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                           <n-switch
                             service="Limit accesss"
                             :disabled="!lan_protection.enabled"
                             @input="handleChangeLanProtection($event, 'device_access')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/lan-protection' }]"
                           />
                          </b-col>
                        </b-row>
                      </b-container>
                     </b-row>
                   </b-col>
                   <b-col :sm="6" :md="6" :xl="6" key="Router" v-if="false">
                    <!-- Hidden because RouterSecure does not support to enable/disable Router Protection Service in ASM 30.1 -->
                     <b-row no-gutters>
                        <b-col cols="8" class="has-label pt-2">
                         <h5  style="white-space: nowrap">
                           {{ t('Router Protection') }}
                         </h5>
                        </b-col>
                        <b-col cols="3">
                          <n-switch
                             v-model="router_protection.enabled"
                             service="Router Protection"
                             @input="handleChangeRouterProtection($event, 'enabled')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/router-protection' }]"
                           />
                        </b-col>
                     </b-row>
                     <b-row v-if="router_protection.enabled">
                      <b-container>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Router Allowed List Enforcement') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                           <n-switch
                             v-model="router_protection.allow_enforcement"
                             service="Router allowList"
                             :disabled="!router_protection.enabled"
                             @input="handleChangeRouterProtection($event, 'allow_enforcement')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/router-protection' }]"
                           />
                          </b-col>
                        </b-row>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Router DNS Enforcement') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                            <n-switch
                              v-model="router_protection.dns_settings"
                              service="Router DNS Enforcement"
                              :disabled="!router_protection.enabled"
                              @input="handleChangeRouterProtection($event, 'dns_settings')"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/router-protection' }]"
                            />
                          </b-col>
                        </b-row>
                      </b-container>
                     </b-row>
                      <b-row v-else>
                      <b-container>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Router Allowed List Enforcement') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                           <n-switch
                             service="Router allowList"
                             :disabled="!router_protection.enabled"
                             @input="handleChangeRouterProtection($event, 'allow_enforcement')"
                             color="allot"
                             :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/router-protection' }]"
                           />
                          </b-col>
                        </b-row>
                        <b-row no-gutters>
                          <b-col cols="8" class="has-label pt-2">
                            <h5 class="my-auto" style="white-space: nowrap; margin-left: 2em;">
                              {{ t('Router DNS Enforcement') }}
                            </h5>
                          </b-col>
                          <b-col cols="3">
                            <n-switch
                              service="Router DNS Enforcement"
                              :disabled="!router_protection.enabled"
                              @input="handleChangeRouterProtection($event, 'dns_settings')"
                              color="allot"
                              :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/rs/router-protection' }]"
                            />
                          </b-col>
                        </b-row>
                      </b-container>
                     </b-row>
                   </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
        </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters }   from 'vuex';
import { Switch }                   from "@/components";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "router_secure_status",
  components: {
    [Switch.name]: Switch,
    PageTitleCard
  },

  computed: {
    ...mapGetters('ispServices/services', ['global_configurations']),
    service_status() {
        let global_service_status =  _.get(this, 'global_configurations')
        let store_status = this.$store.getters["routerSecure/status/serviceStatus"]

        if (!store_status.content_filter) {
          store_status = {
            'content_filter': {'global': false},
            'malware': {'global': false}
          }
        } else {
          store_status.content_filter.global = global_service_status && global_service_status.parental_control && global_service_status.parental_control.enabled
          store_status.malware.global = global_service_status && global_service_status.threat_protection && global_service_status.threat_protection.enabled
            && global_service_status.threat_protection.antibotnet_enabled && global_service_status.threat_protection.antimalware_category_enabled
             && global_service_status.threat_protection.antiphishing_enabled && global_service_status.threat_protection.antivirus_enabled
        }

        return store_status
    },
    lan_protection() {
      return this.$store.getters["routerSecure/status/lanProtection"];
    },
    router_protection() {
      return this.$store.getters["routerSecure/status/routerProtection"];
    }

  },
  methods: {
		...mapActions('ispServices/services', ['getGlobalConfigurations']),
    loadServiceStatus() {
      this.$store.dispatch("routerSecure/status/getServiceStatus")
    },
    loadProtection() {
      this.$store.dispatch("routerSecure/status/getLanProtection")
      this.$store.dispatch("routerSecure/status/getRouterProtection")
    },
    handleChangeServiceStatus(value, service) {
      this.$store
        .dispatch("routerSecure/status/setServiceStatus", {
          name: service,
          status: value,
        })
        .then((res) => {
          this.loadServiceStatus();
        })
        .catch((err) => { });
    },
    handleChangeLanProtection(value, service) {
      this.$store
        .dispatch("routerSecure/status/updateLanProtection", {
          name: service,
          status: value,
        })
        .then(() => {
          this.loadProtection();
        })
    },
     handleChangeRouterProtection(value, service) {
      this.$store
        .dispatch("routerSecure/status/updateRouterProtection", {
          name: service,
          status: value,
        })
        .then(() => {
          this.loadProtection();
        })
    },
  },
  mounted() {
    this.loadServiceStatus();
    this.loadProtection();
  },
	beforeMount() {
		this.getGlobalConfigurations();
	},
};
</script>
<style>

</style>
