<template>
   <b-modal id="filter-modal" size="lg" ref="filter_modal" @hide="hideFilterModal">	
				<template #modal-header>
          <h3  class="modal-title white-content  mx-auto">{{t('Advanced Filtering')}}</h3>
				 	<button type="button" aria-label="Close" @click="hideFilterModal" class="close">x</button>
        </template>
				<template #modal-footer class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary btn-block btn-round"
						@click="hideFilterModal"
					> {{t('Cancel')}} </button>
					<button
						type="button"
						class="btn btn-primary btn-block btn-round"
						@click="handleApplyFilter"
						:disabled="noFiltersSelected"
					> {{t('Apply filter')}} {{`(${routers_filtered})`}} </button>
				</template>	
        <form class="form-group form-margin" @submit.prevent="handleApplyFilter">               
	        <div class="select-width">
				<b-row>
					<b-col xl="12">
						{{t('Model')}}
						<el-select class="select_margin select-primary select-style"
							v-model="data.router_model" @change="(value) =>changeSelect(value,'router_model')"
							multiple filterable allow-create :placeholder="t('Type a router model')">
							<el-option
								class="select-primary"
								v-for="item in modelOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</b-col>
				</b-row>
				<b-row>
					<b-col xl="12">
			            {{t('Firmware')}}
						<el-select class="select_margin select-primary select-style"
									v-model="data.firmware" @change="(value) =>changeSelect(value,'firmware_version')"
									multiple filterable allow-create :placeholder="t('Type a router firmware')">
    					 		<el-option
								  class="select-primary"
    					 		  v-for="item in firmwareOptions"
    					 		  :key="item.value"
    					 		  :label="item.label"
    					 		  :value="item.value">
    					 		</el-option>
  						 </el-select>
          			</b-col>
				</b-row>
				<b-row>
					<b-col xl="12">
        			    {{t('Active Agent version')}}
             			<el-select class="select_margin select-primary select-style"
						 		v-model="data.agent_version" @change="(value) =>changeSelect(value,'current_agent_version_id')"
								multiple filterable allow-create :placeholder="t('Active Agent version')">
							<el-option
								class="select-primary"
								v-for="item in versionOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
		          	</b-col>
				</b-row>
				<b-row>
					<b-col xl="12" class="mb-2">
			            {{t('Target Agent Package')}}
					</b-col>
					<b-col xl="12" class="mb-2">
    		        	<el-select class="select_margin select-primary select-style"
								v-model="data.target_version" @change="(value) =>changeSelect(value,'target_agent_package_id')"
								multiple filterable allow-create :placeholder="t('Target Agent Package')">
							<el-option
								class="select-primary"
								v-for="item in targetVersionOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
  						</el-select>
		          	</b-col>
				</b-row>
    	     	<b-row>
					<!-- Atribute name & atribute value component -->
					<b-col xl="12" class="mb-2">
						{{t('Attribute name and value')}}
					</b-col>
					<b-col xl="12" class="mb-2">
        			    <el-select class="select_margin select-primary select-style"
								v-model="data.attributes" @change="(value) =>changeSelect(value,'attributes')"
								multiple filterable allow-create :placeholder="t('Type an attribute')">
							<el-option
								class="select-primary"
								v-for="item in attributeOptions"
								:key="`${item.name}-${item.value}`"
								:label="`${item.name}-${item.value}`"
								:value="`${item.name}-${item.value}`">
							</el-option>
	  					</el-select>
    		      	</b-col>
				</b-row>
			</div>
			<b-row>
				<b-col xl=12 class="mt-4 mb-2">{{t('Router Status') + ':'}}</b-col>
				<b-col class="ml-4">
					<el-radio-group v-model="data.status"  @change="(value) =>changeSelect(value,'status')">
						<el-radio-button label="All">{{ t('All') }}</el-radio-button>
						<el-radio-button label="Active">{{ t('Active') }}</el-radio-button>
						<el-radio-button label="Inactive">{{ t('Inactive') }}</el-radio-button>
					</el-radio-group>
				</b-col>
            </b-row>
			<b-row>
				<b-col  xl=12 class="mt-4 mb-2">{{t('Show routers in Reboot Loop State') + ':'}}</b-col>
				<b-col class="ml-4">
	            	<el-radio-group v-model="data.reboot"  @change="(value) =>changeSelect(value,'reboot_loop')">
    	        		<el-radio-button label="All">{{ t('All') }}</el-radio-button>
        	    		<el-radio-button label="Active">{{ t('In Reboot Loop') }}</el-radio-button>
            			<el-radio-button label="Inactive">{{ t('In Normal Mode') }}</el-radio-button>
         	 		</el-radio-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col xl=12 class="mt-4 mb-2">{{t('Show routers in Safe Mode State') + ':'}}</b-col>
				<b-col class="ml-4">
					<el-radio-group v-model="data.safe"  @change="(value) =>changeSelect(value,'safe_mode')">
						<el-radio-button label="All">{{ t('All') }}</el-radio-button>
						<el-radio-button label="Active">{{ t('In Safe Mode') }}</el-radio-button>
						<el-radio-button label="Inactive">{{ t('In Normal Mode') }}</el-radio-button>
					</el-radio-group>
				</b-col>
			</b-row>
            <!-- More filters -->
			<b-row>
				<b-col xl=12 class="mt-4 mb-2">
					{{t('More filters')}}
				</b-col>
				<b-col class="ml-4">
					<el-radio-group v-model="data.router" @change="(value) =>changeSelect(value,'router_more')">
						<el-radio-button label="None">{{ t('None') }}</el-radio-button>
						<el-radio-button label="Show">{{ t('Show') }}</el-radio-button>
						<el-radio-button label="Hide">{{ t('Hide') }}</el-radio-button>
					</el-radio-group>
				</b-col>
			</b-row>
			<b-row v-if="data.router !== 'None'">
				<more-filter :filter="data.more" :periodOptions="periodOptions" @edit="(value) =>changeSelect(value,'more_filter')"/>
			</b-row>
		</form>
    </b-modal>
</template>

<script>
import { Select, Option, Input, Tag, Button, FormItem, RadioGroup, Radio } from 'element-ui';
import MoreFilter from './MoreFilter.vue';
import {DEFAULT_FILTER, transformFilterToBackQuery} from '@/util/selecTableRSUtil'

export default {
	name: 'advanced-filtering',
	components: {
		'el-select': Select,
		'el-option': Option,
		'el-input' : Input,
		'el-tag': Tag,
		'el-button': Button,
		'el-form-item': FormItem,
		'more-filter': MoreFilter
	},
  props: {
    filter: {
      type: Object,
    },
    handleOpen: {
      type: Function,
      default: () => {}

    }, 
    close: {
      type: Function,
      default: () => {}
		} ,
		show: {
			type: Boolean, 
			default: false
		}

		},
	data() {
    return {
			data: {
				...DEFAULT_FILTER
			},
			query : {},
			routers_filtered: undefined,
		}
	 },	
		watch: {
			show(newValue) {
				if (newValue) {
					this.data = {...this.filter}
					this.$store.dispatch('routerSecure/troubleshooting/getRoutersFiltered', {wait: 0, query: transformFilterToBackQuery(this.data)})
					.then( routers_number => {
								this.routers_filtered = routers_number
					})
					this.$refs.filter_modal.show()
				} else {
					this.data = {...DEFAULT_FILTER,
					more: {
						...DEFAULT_FILTER.more,
					},
					router: 'None'
					}
					this.$refs.filter_modal.hide()
				}
			},
		
		},
	methods: {
		hideFilterModal() {
			this.data = {...DEFAULT_FILTER}
			this.$emit('close')
		},
		handleApplyFilter() {
			if ( this.data.target_version){
				this.data.target_version_tag = this.data.target_version.map( target_version => {
					return this.getTargetVersion(target_version, false)
				}, this)
			}
			this.$emit('save', this.data)
		},
	
		changeSelect(value, type) {
			this.$store.dispatch('routerSecure/troubleshooting/getRoutersFiltered', {wait: 0, query: transformFilterToBackQuery(this.data)})
			.then( routers_number => {
						this.routers_filtered = routers_number
			})
		},
		getAttributes() {
			this.$store.dispatch('routerSecure/troubleshooting/getAttributes')
		},
		getTargetVersion(target_agent_package_id, show_time = true){
			if (!target_agent_package_id)
				return null

			const agents = this.$store.getters['routerSecure/troubleshooting/agent_packages']
			if (!!agents){
				let agent = agents.filter( (item) => item.agent_package_id == target_agent_package_id )
				if (agent && agent.length > 0){
					return agent[0].router_model + ' - ' + agent[0].agent_version_id + 
              			(agent[0].upload_time && show_time ? " - " + this.toLocaleString(this.datetimeFromISO(agent[0].upload_time)) : '')
				}
				else{
					return target_agent_package_id
				}
			}
			else
				return null
		},
	},	
	computed: {
		 modelOptions(){
			return this.$store.getters["routerSecure/troubleshooting/modelOptions"]
		 },
		 firmwareOptions() {
			return this.$store.getters["routerSecure/troubleshooting/firmwareOptions"]
		 },
		 versionOptions() {
		 	return this.$store.getters["routerSecure/troubleshooting/activeVersionOptions"]
		 },
		 targetVersionOptions() {
		 	const versions = this.$store.getters["routerSecure/troubleshooting/targetVersionOptions"]
			let targets = []
			let isDefault = false
			versions.forEach( item => {
				targets.push({
					value: item.value,
					label: this.getTargetVersion(item.value) || this.t('default')
				})
				if (!item.value)
					isDefault = true
			});
			if (!isDefault)
				targets.unshift( { value: '', label: this.t('default')})

			return targets
		 },
		 attributeOptions() {
		 	return this.$store.getters["routerSecure/troubleshooting/attributeOptions"]
		 },
		 periodOptions() {
			return  [
				{ value: "minutes", label: this.t("Minutes") },
				{ value: "hours", label: this.t("Hours") },
				{ value: "days", label: this.t("Days") },
				{ value: "weeks", label: this.t("Weeks") },
				{ value: "months", label: this.t("Months")}
			]
		 },
		 noFiltersSelected() {
			 let filtersSelected = [];
			 this.data.router_model.length !== 0 && filtersSelected.push('router_model');
			 this.data.firmware.length !== 0 && filtersSelected.push('firmware');
			 this.data.agent_version.length !== 0 && filtersSelected.push('agent_version');
			 this.data.target_version.length !== 0 && filtersSelected.push('target_version');
			 this.data.attributes.length !== 0 && filtersSelected.push('attributes');
			 this.data.status !== 'All' && filtersSelected.push('status');
			 this.data.reboot !== 'All' && filtersSelected.push('reboot');
			 this.data.safe !== 'All' && filtersSelected.push('safe');
			 this.data.router !== 'None'
			 	&& (this.data.more.releaseBoot.apply || this.data.more.releaseSafe.apply || this.data.more.upgradedVersion.apply)
			 	&& filtersSelected.push('more');
			 return filtersSelected.length === 0;
		 }
	},
	mounted() {
			this.getAttributes()
			this.$store.dispatch('routerSecure/troubleshooting/getRoutersFiltered', {wait: 0, query: this.data})
      .then( routers_number => {
				 this.routers_filtered = routers_number
      })
	}
}
</script>

<style lang="scss">
 	// Tags styles

span.el-tag {
	border-radius: 30px;
}

.el-select .el-select__tags .el-tag .el-tag__close.el-icon-close {
	background-color: inherit;
	color: #8b8b8b;
	
}

.el-select .el-select__tags .el-tag .el-tag__close.el-icon-close:hover {
	background-color: inherit;
	color: #8b8b8b;
	font-size: 0.875rem;
}

.el-select .el-select__tags .el-tag {
	  border-radius: 30px;

}	

.row-reverse {
	display: flex;
	flex-flow: row-reverse;
}
.el-tag .el-tag__close {
	margin-left: 8px;
}

.table-icon{
  margin-left: 0 !important; 
}

.select_margin {
	margin-bottom: 12px;
}

.modal-container {
	min-width: 771px;
  padding-right: 20%;
  padding-left: 20%;
}

.form-margin {
	margin: 0 10px 40px 20px;
}

.select-width {
	max-width: 35em;
}

.frequency-container {
	display: inline-block;
}

.form-group .el-select.upgraded-text {
	margin: 0 0 0 15px;
  width: 150px;
	display: inline-block;
	color: #32325d;
}

.form-group .el-input__inner.upgraded-number {
	margin: 0 0 0 30px;
  width: 55px;
  display: inline-block;
  padding: 0 10px;
	border-radius: 30px;
}

</style>