<template>
  <div>
    <page-title-card
      :page-title="t('Default Agent Version')"
      :page-description="t('agent_package_text')"
    >
      <template #button>
        <n-button
          type="primary"
          size="md"
          round
          block
          native-type="submit"
          @click.native="newAgent"
          :visible_for_permissions='[{actions: ["POST"], path: "/subsystem/rs/router-default-agent-packages"}]'
        >
          <i><svgicon class="icon" icon="icon-new"/></i>
          {{ t('New') }}
        </n-button>
      </template>
    </page-title-card>
    <b-card class="card-user" no-body>
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-three"></div>
        <div class="block block-four"></div>
      </div>
      <b-card-body style="margin-top: -30px; min-height: 75px">       
      </b-card-body>
      <b-card-body style="padding: 0px;">
        <paginated-table :rows='agents'
                        :columns='columns'
                        :actions="actions"
                        :updating="updating"
                        :search="['router_model']"
                        :serverpagination="null"
                        @editAgent="handleEditAgent"  @removeAgent="handleRemoveAgent" />
    </b-card-body>

    <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
            <i class="now-ui-icons arrows-1_refresh-69" />
            {{ t('Update now') }}
        </div>
        <div v-else>
            <i class="now-ui-icons loader_refresh spin"/>
            {{ t('Updating...') }}
        </div>
    </template>
    </b-card>
  </div>
</template>

<script>
import { PaginatedTable } from '@/components';
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "rs_default_agent_package",

  components: {
    PaginatedTable,
    PageTitleCard
  },
  data() {
    return {
      agents_list: new Map(),
      agent: {
        default_mapping_id : '',
        router_model : '',
        firmware_version : '',
        agent_package_id : '',
        agent_version_id : ''
      },
      updating: false,
      columns: [
        {
            prop: 'router_model',
            label: this.$t('Router Model'),
            minWidth: 130,
        },
        {
            prop: 'firmware_version',
            label: this.$t('Router Firmware'),
            minWidth: 130,
        },
        {
            prop: 'agent_version_id',
            label: this.t('Default Agent'),
            minWidth: 130,
        },

      ],
      actions: {
        minWidth: 100,
        label: this.$t('Actions'),
        fixed: 'right',
        items: [
            { type: 'warning', icon: 'now-ui-icons business_badge', event: 'editAgent', action: this.t('Edit'),
              visible_for_permissions: [{actions: ["PUT"], path: "/subsystem/rs/router-default-agent-packages/.*"}]},
            { type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'removeAgent', action: this.t('Remove'),
                confirm: false, visible_for_permissions: [{actions: ["DELETE"], path: "/subsystem/rs/router-default-agent-packages/.*"}] },

        ]
      },
    };
  },
  async mounted() {
    this.refresh(0)
  },
  computed: {
		agents(){
      let ret = [];
      let vm = this
      this.agents_list.forEach( (value, key) => {
          let item = {
            default_mapping_id : value.default_mapping_id,
            router_model : value.router_model,
            firmware_version : value.firmware_version,
            agent_package_id : value.agent_package_id,
            agent_version_id : value.agent_version_id
          }
          ret.push(item)
      })
      return ret;
    }
  },
  methods: {
    newAgent(){
      this.$router.push( {path: '/rs/General/GeneralManagement/DefaultAgentVersion/new'} )
    },
    async handleEditAgent(data) {
      this.$router.push( {path: '/rs/General/GeneralManagement/DefaultAgentVersion/edit/'+ encodeURIComponent(data.row.default_mapping_id) } )
    },
    async handleRemoveAgent(data){
      this.$bvModal.msgBoxConfirm(this.t('Are you sure you want to delete this default agent version'), {
          okTitle: this.$t('accept'),
          cancelTitle: this.$t('cancel')
      })
      .then(confirmed => {
          if(confirmed) {
              this.$store.dispatch('routerSecure/defaultAgentVersion/deleteDefaultAgent', data.row.default_mapping_id  ).then( res => {
              this.$store.commit("setSuccess", this.$t("Default Agent Version") + " " + this.$t("successfully removed"))
              this.agents_list = res
           })
          .catch( err => this.refresh(0) )
          }
      })
      .catch(() => {
      })
    },

    refresh(wait){
      let _vm = this
      this.updating = true;

      this.$store.dispatch('routerSecure/defaultAgentVersion/getDefaultAgents')
      .then( res => {
          _vm.agents_list = res
          this.updating = false
      })
      .catch( err => {
          this.updating = false
      });
    }
  },
};
</script>
<style lang="scss" scoped>

  .prefix {
    margin-right: 15px;
  }

</style>
