<template>
  <div>
    <div class="content-selection">
      <div class="left-side">
        <span class="router-selection-label">{{`${isAllButtonSelected ? getRoutersSelected  :rows_selected === 0 ? queryCount + t('routers found')  :  rows_selected +  t('routers on this page are selected')}`}} </span>
        <div class="select-option-text" @click="toggleSelection">
            {{`${isAllButtonSelected ? t('Clear Selection') :isSelectAll ? t('Select All') + ` routers ` : t('Select first') + ' ' + bulkSize }`}}
        </div>
        <span class="router-info">{{`(${t('bulk select max')} ${bulkSize})`}} </span>
        <n-button
          v-if="showApplyChangesButton"
          id="button-bulk"
          type="primary"
          size="md"
          round
          @click.native="handleApplyChanges"
          :visible_for_permissions='[{actions: ["POST"], path: "/subsystems/rs/routers/reset-state/.*"}]' >          
            <span class="iconify button-icon" data-icon="feather-edit" />
            {{ t('Apply changes') }}
        </n-button>
      </div>
      <div class="right-side">
        <n-button
          id="button-filter"
          type="primary"
          size="md"
          round
          @click.native="showFilterModal"
        >
          <span class="iconify  button-icon" data-icon="mdi:filter-outline"/>
          {{ t('Advanced Filtering') }}
        </n-button>
      </div>
    </div>	
    <div class="bulk_selection_alert">
      <p v-if="isAllButtonSelected">* {{ t('bulk_selection_alert') }}</p>
    </div>
  </div>
</template>

<script>
import { Tag, Button} from 'element-ui';

export default {
	name: 'selection-component',
	components: {
		'el-tag': Tag,
		'el-button': Button,
	},
  props: {
   rows_selected: {
    type: Number,
    default: 0
   },
   queryCount: {
     type: Number,
     default: 0
   },
   bulkSize: {
     type: Number,
     default: 0
   },
   isAllButtonSelected: {
     type: Boolean,
     default: false
   },
  
	},
	methods: {
    handleApplyChanges() {
      this.$emit('apply-changes')
    },
    toggleSelection() {
      this.$emit('selection')
    },
    showFilterModal() {
      this.$emit('show-modal')
      }, 
   },
   computed: {
      isSelectAll() {
       // console.log('bulk size:', this.bulkSize, this.queryCount)
       return this.bulkSize === 0 || (this.queryCount <=  this.bulkSize)
     },
     showApplyChangesButton() {
       return this.rows_selected > 0 || this.isAllButtonSelected
     },
     getRoutersSelected() {
      if (this.isSelectAll) {
        return (`${this.queryCount + this.t('routers selected')}`)  
      } else {
        return (`${this.bulkSize + this.t('routers selected')}`)  
      }
      
    } 

   }
	
}
</script>

<style lang="scss">

  .content-selection {
    display: flex;
    margin: 10px 0 0 0;
  }

  .left-side {
    flex:1;
    padding: 0 20px;
  }

  .right-side {
    margin-left: auto;
    padding: 0 20px;

  }

	.select-option-text {
		color: #ba54f5;
    font-weight: 300;
		cursor: pointer;
		display: inline-block;
    margin: 24px 6px 0 0;
	}

  .router-selection-label {
		display: inline-block;
    margin: 24px 30px 24px 0;
	}

  .router-info {
    display: inline;
    margin: 24px 30px 0 0;
    color: #979999;
  }
  .bulk_selection_alert {
    font-size: 0.6875rem;
    min-height: 1em;
  }

</style>