<template functional>
  <div v-if="typeof props.row[props.column.prop] === 'object' && props.row[props.column.prop] !== null">
    <div v-if="props.row[props.column.prop]" class="d-flex flex-column justify-content-center">
      <div v-if="props.row[props.column.prop].mac && props.row[props.column.prop].mac.length > 0 && props.row[props.column.prop].mac[0] !== '' ">
        <span class="text-primary">{{`MAC:`}}</span>
        <p v-for="mac in props.row[props.column.prop].mac" :key="mac">
          {{ mac }}
        </p>
      </div>
      <div v-if="props.row[props.column.prop].serial && props.row[props.column.prop].serial.length > 0 && props.row[props.column.prop].serial[0] !== '' ">
        <span class="text-primary">{{`SN:`}}</span>
        <p v-for="serial in props.row[props.column.prop].serial" :key="serial">
          {{ serial }}
        </p>
      </div>
     
    </div>
  </div>
</template>

<script>
  export default {
    name: 'identifiers-formatter',
  }
 
</script>

<style scoped lang=scss>
  
</style>