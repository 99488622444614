<template>
<div>
  <page-title-card
      :page-title="t('Default Agent Version')"
      :page-description="t('From this page, you can map router models and router firmware to default agent versions')"/>
  <b-card >
    <b-card-body fuild style="max-width: 2560px;" >
      <b-row>
        <b-col cols="3" style="text-align:right">
          <b-card>
            {{ t('Router Model')}}:
          </b-card>  
        </b-col>
        <b-col cols="9">
          <el-select :disabled="!permission_granted || !newPackage"
          v-model="router_model"
          v-validate="{ required: true }"                          
          class="select-primary"  
          :placeholder="t('Router Model')"
          size="large"
          value="Primary"
          style="width:300px"
          >
          
          <el-option v-for="item in router_model_list"
              class="select-primary"
              :value="item"
              :label="item"
              :key="item">
          </el-option>
          </el-select>
          <p v-if='errorModelMessage' class="errorMessage">{{t(errorModelMessage)}}</p>
        </b-col>
      </b-row> 

      <b-row>
        <b-col cols="3" style="text-align:right">
          <b-card>
            {{t('Firmware Version')}}:
          </b-card>  
        </b-col>
        <b-col cols="9" >
          <el-autocomplete v-if="firmware_versions_any"
              :disabled="!permission_granted || !newPackage"
              v-model="firmware_version_value"
              :fetch-suggestions="fetchFirmwares"
              @select="selectFirmware"
              class="select-primary"
              v-validate="{ required: true }"
              :placeholder="t('Firmware Version')"
              size="large"
              style="width:300px">
              <template slot-scope="{item}">
                <div>{{ item.value }}</div>
              </template>
          </el-autocomplete>
          <el-select v-else
              :disabled="!permission_granted || !newPackage"
              v-model="firmware_version"                         
              class="select-primary" 
              v-validate="{ required: true }" 
              :placeholder="t('Firmware Version')"
              size="large"
              value="Primary"
              style="width:300px"
              >
              <el-option v-for="item in firmware_versions"
                  class="select-primary"
                  :value="item.key"
                  :label="item.value"
                  :key="item.key">
              </el-option>
            </el-select>
            <p v-if='errorFirmwareMessage' class="errorMessage">{{t(errorFirmwareMessage)}}</p>
        </b-col>
      </b-row>  

 <b-row>
        <b-col cols="3" style="text-align:right">
          <b-card>
            {{t('Default Agent')}}:
          </b-card>  
        </b-col>
        <b-col cols="9">
          <el-select :disabled="!permission_granted"
          v-model="agent_package_id"                         
          class="select-primary" 
          v-validate="{ required: true }" 
          :placeholder="$t('Default Agent')"
          size="large"
          value="Primary"
          style="width:300px">
          <el-option v-for="item in agent_version_list"
              class="select-primary"
              :value="item.key"
              :label="item.label"
              :key="item.key">
          </el-option>
          </el-select>
          <p v-if='errorVersionMessage' class="errorMessage">{{t(errorVersionMessage)}}</p>
        </b-col>
      </b-row>   
        <b-row class="button-padding">
          <b-col cols="8"></b-col>
          <b-col cols="2">
              <n-button
                id="cancel-cluster-server-creation"
                @click.native="handleCancel"
                type="secondary"
                size="md"
                round
                block
              >
                {{ t('Cancel') }}
              </n-button>        
          </b-col>
          <b-col cols="2">
            <n-button
              id="save-cluster-server-button"
              @click.native="handleSave"
              type="primary"
              size="md"
              round
              block                
            >          
              {{ t('Save') }}
            </n-button>
          </b-col>        
      </b-row>
    </b-card-body>
  </b-card>  
</div>
</template>

<script>
import permissions from '@/mixins/permissions'
import {mapActions} from "vuex";
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";

export default {
    name: "rs_default_agent_package_edit",
    components: {
      PageTitleCard
    },
    mixins: [permissions],
    
    props: ["agent_id"],
    
    data() {
      return {
        newPackage : true,
        agent_list : new Map(),
        router_model : '',        
        agent_package_id : '',
        default_mapping_id : '',
        firmware_version : '',
        firmware_version_value: '',
        errorModelMessage: undefined,
        errorFirmwareMessage: undefined,
        errorVersionMessage: undefined        
      }
    },

    computed: {    
		  router_model_list(){
        let ret = [];        
        this.agent_list.forEach( (value, key) => {         
          ret.push(key)
        })
        return ret;
      },

      firmware_versions(){
        if( this.newPackage ){
          this.firmware_version = ''
          this.agent_package_id = ""
        }

        if ( !!!this.agent_list || this.agent_list.length === 0)
          return []

        if(this.router_model != "" ){          
          let ret = [];                  
          this.agent_list.get(this.router_model).forEach( (value, key) => {
            const ma = [...ret, ...value.firmware_versions ]
            let set = new Set();
            ret = ma.filter(item=>{
              if( !set.has(item.key) ){
                set.add(item.key)
                return true;
              }
              return false;
            }, set);
          })          
          return ret
        } else
          return []
      },
      firmware_versions_any(){
        return !!!this.agent_id && this.firmware_versions.some( item => item.key === "*")
      },

      agent_version_list(){
        let ret = [];        
        let vm = this;

        if( this.newPackage )
          this.agent_package_id = "" 
        
          if( this.router_model != '' && this.firmware_version != ''  )
            this.agent_list.get(this.router_model).forEach( (value, key) => {
              let index = value.firmware_versions.findIndex(item => {
                  return item.key == this.firmware_version || item.key == '*'
              });
              
              if( index >= 0){         
                let agent = {
                  key : value.agent_package_id,
                  label : value.agent_version_id + " - " + this.toLocaleString(this.datetimeFromISO(value.upload_time))
                }
                ret.push(agent)
              }
            })          
        return ret;    
      }   
    },

    methods : {
      ...mapActions('settings', ['setIsFormDirty']),
      handleCancel() {
        this.clearErrorMessages()
        this.$router.replace({
          path: "/rs/General/GeneralManagement/DefaultAgentVersion"
        });
      },

      async handleSave() {
        let isValid = await this.$validator.validateAll();
        let vm = this
        if( isValid ){
          this.clearErrorMessages()
          this.setIsFormDirty(false);
          if( this.newPackage ){
              this.$store.dispatch("routerSecure/defaultAgentVersion/addDefaultAgent", {
                  router_model : vm.router_model,
                  firmware_version : vm.firmware_version,
                  agent_package_id : vm.agent_package_id,
                  agent_version_id : vm.agent_list.get(vm.router_model).get(vm.agent_package_id).agent_version_id
              })
              .then((res) => {            
                this.$store.commit("setSuccess", this.$t("Default Agent Version") + " " + this.$t("successfully created"));
                  this.$router.replace({
                    path: "/rs/General/GeneralManagement/DefaultAgentVersion",
                  });
                })
              .catch()
            }  else {              
              this.$store.dispatch("routerSecure/defaultAgentVersion/updateDefaultAgent", {
                  default_mapping_id : vm.default_mapping_id,
                  router_model : vm.router_model,
                  firmware_version : vm.firmware_version,
                  agent_package_id : vm.agent_package_id,
                  agent_version_id : vm.agent_list.get(vm.router_model).get(vm.agent_package_id).agent_version_id
              })
              .then((res) => {            
                this.$store.commit("setSuccess", this.$t("Default Agent Version") + " " + this.$t("successfully updated"));
                  this.$router.replace({
                    path: "/rs/General/GeneralManagement/DefaultAgentVersion",
                  });
                })
              .catch()
            }                      
        } else {
          if (this.router_model === '')  this.errorModelMessage = this.t('router model warning message')          
          if (this.firmware_version === '')  this.errorFirmwareMessage = this.t('router firmware version message')
          if (this.agent_package_id === '')  this.errorVersionMessage = this.t('router agent version message')          
        }
      },
      clearErrorMessages() {
        if (this.errorModelMessage) this.errorModelMessage = undefined
        if (this.errorFirmwareMessage) this.errorFirmwareMessage = undefined;
        if (this.errorVersionMessage) this.errorVersionMessage = undefined;
      },
      setDirty() {
        this.setIsFormDirty(true);
      },
      fetchFirmwares(queryString, cb) {
        let results = this.firmware_versions
        if (!!queryString){
          results = this.firmware_versions.filter( (firmware) => {
              return (
                firmware.key.toLowerCase().includes(queryString.toLowerCase()) ||
                firmware.value.toLowerCase().includes(queryString.toLowerCase())
              );
            })
          if (!results.some( item => item.key == queryString || item.value == queryString))
            results.unshift({ key: queryString, value: queryString})
        }
        cb(results);
      },
      selectFirmware(value){
        this.firmware_version = value.key
      }
    },    
    watch: {
      router_model(model) {
        if (model !== '' && this.errorModelMessage) this.errorModelMessage = undefined
        if (this.newPackage) this.setDirty();
      },
      firmware_version(firmware) {
        if (firmware !== '' && this.errorFirmwareMessage) this.errorFirmwareMessage = undefined
        if (this.newPackage) this.setDirty();
      },
      agent_package_id(package_id) {
        if (package_id !== '' && this.errorVersionMessage) this.errorVersionMessage = undefined
      },
      firmware_version_value(value){
        if (!!value){
          let results = this.firmware_versions.filter( (firmware) => firmware.value === value)
          if (!!results && results.length == 1)
            this.firmware_version = results[0].key
          else
            this.firmware_version = value
        }
      }
    },                 
    async mounted() {              
      if( this.agent_id ){
        this.$store.commit("settings/startLoading", true);              
        this.newPackage = false;

        this.$store.dispatch('routerSecure/defaultAgentVersion/getDefaultAgent', this.agent_id)
        .then( res => {   
          if( res ){                              
            
            let router_model = res.router_model
            let agent_package_id = res.agent_package_id        
            let default_mapping_id = res.default_mapping_id                                          
            let firmware_version = res.firmware_version
            
            this.$store.dispatch('routerSecure/defaultAgentVersion/getAgentsByRouterModel', {router_model : router_model, agent_package_id : agent_package_id, firmware_version : firmware_version})
            .then( res => {   
              if( res ){       
               console.log(res)
                
                this.agent_list = res 


                this.router_model = router_model
                this.agent_package_id = agent_package_id        
                this.default_mapping_id = default_mapping_id                                          
                this.firmware_version = firmware_version
                

              }
            })
            .catch(() => {
              this.$store.commit("settings/finishLoading", true);
            })


            this.$store.commit("settings/finishLoading", true);
          }
        })
        .catch(() => {
          this.$store.commit("settings/finishLoading", true);
        })
      } else {
        this.$store.commit("settings/startLoading", true);
        this.$store.dispatch('routerSecure/defaultAgentVersion/getAgentsByRouter')
        .then( res => {        
            this.agent_list = res 
            this.$store.commit("settings/finishLoading", true);
        })          
        .catch(() => {
          this.$store.commit("settings/finishLoading", true);
        });         
      }                     
  }
      
}

</script>    

<style scoped lang=scss>
  
  .default-tag {
    background-color: #cfcfcf;
    border-color: #a7a7a8;
    color: #444444;
    white-space: normal;
    overflow-wrap: anywhere;
    height: auto;
  }
  .default-tag .el-tag__close, .el-tag.el-tag--default .el-tag__close {
    color: #cfcfcf !important;
  }

  .errorMessage {
    color: red !important;
  }
</style>