<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('troubleshooting_text')"
    />
    <b-card class="card-troubleshooting" no-body>
        <b-card-body style="padding: 0px">
          <select-table
            :rows="rows"
            id="troubleshooting-table"
            :columns="realColumns"
            :actions="actions"
            :search="search_options"
            :searchRestrictions="searchRestrictions"
            :searchValidations="searchValidations"
            :showPartialMatchText="false"
            v-model="search_field_value"
            :itemsPerPage="query.limit"
            :updating="updating"
            :rowsSelected="rowsSelected"
            :serverpagination="search_pagination"
            :queryCount="query_count"
            :bulkSize="bulk_size"
            :isAllButtonSelected="isAllButtonSelected"
            :reserveSelection="true"
            @prev="handlePrevPage"
            @next="handleNextPage"
            @pagination="handlePagination"
            @item-edit="handleEdit"
            @item-remove="handleRemove"
            @number-rows-selected="handleRows"
            @apply-changes="showBulkModal"
            @apply-filter="changeFilter"
            @clear-filter="handleClearFilter"
            @close-filter="handleCloseTagFilter"
            @search-clear="handleClearSearch"
            @select-all="handleButtonSelectAll(true)"
            @unselect-all="handleButtonSelectAll(false)"
          >
            <template v-slot:expand="props">
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="10">
                  <template v-if="props.row.attributes && props.row.attributes.length > 0">
                    <h5 >{{t('Attributes')}}:</h5>
                    <el-tag class="attributes-tag" v-for="item in props.row.attributes"
                            :key="`${item.name}-${item.value}`"
                            :label="`${item.name}-${item.value}`"
                            :value="`${item.name}-${item.value}`">
                      {{`${item.name}-${item.value}`}}
                    </el-tag>
                  </template>
                  <template v-else>
                    <h5 >{{t('Attributes')}}:</h5>
                    <span>{{t('Empty')}}</span>
                  </template>
                </b-col>
              </b-row>
            </template>
          </select-table>
        </b-card-body>
        <template v-slot:footer>
          <div class="hr-grey mb-2"/>
          <div @click="reload" v-if="!updating" style="cursor: pointer: display:inline">
            <i class="now-ui-icons arrows-1_refresh-69"/>
            {{t('Update now')}}
          </div>
          <div v-else>
            <i class="now-ui-icons loader_refresh spin"/>
            {{t('Updating')}}
          </div>
        </template>


        <b-modal id="bulk-change" ref="bulk_modal" @ok="handleApplyBulk"
                      :ok-disabled="!!!bulk.update_target && !!!bulk.reboot && !!!bulk.safe"
                      :title="t('Apply Changes')" :ok-title="t('Apply')" :cancel-title="t('cancel')">
          <form class="form-group" @submit.prevent="handleApplyBulk">
            <b-col xl="12" class="mb-2">
              {{t('apply_router_changes_text')}} {{isAllButtonSelected ? Math.min(query_count, bulk_size) : rowsSelected}} {{"routers"}}
            </b-col>
            <b-row>
              <b-col xl="12" class="mb-2">
                <el-checkbox v-model="bulk.safe" :disabled="bulk.update_target || bulk.reboot">{{t('Release Safe Mode')}}</el-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12" class="mb-2">
                <el-checkbox v-model="bulk.reboot" :disabled="bulk.update_target || bulk.safe">{{t('Release Reboot Loop')}}</el-checkbox>
              </b-col>
            </b-row>
            <!-- TODO: reporting interval checkbox & version_selected for future versions -->
            <b-row>
              <b-col xl="12" class="mb-2">
                <el-checkbox v-model="bulk.update_target" :disabled="bulk.safe || bulk.reboot">{{t('Update Target Agent Package')}}</el-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="bulk.update_target">
              <!-- version selected -->
              <b-col xl="5" lg="5">
                <div class="mt-1"><label>{{t('Select version')}}</label></div>
              </b-col>
              <b-col cols="12">
                <el-select class="select-primary"
                           size="large"
                           v-model="bulk.version_selected" :placeholder="t('Select Version')">
                  <el-option
                    v-for="item in agent_options"
                    :key="item.value"
                    :label="item.label"                    
                    :value="item.value">
                    <span class="text-truncate router_model">{{ item.router }}</span>
                    <span class="router_attrs">{{ item.agent }}</span>
                    <span class="router_attrs">{{ item.datef }}</span>
                  </el-option>
                </el-select>
              </b-col>
            </b-row>
            <b-col xl="12" class="mb-2">
              <div class="margin-text">
                {{`${t('This changes makes take a while to apply')}`}}
              </div>
            </b-col>
          </form>
        </b-modal>
        <b-modal id="confirm_bulk" ref="confirm_bulk" @ok="setBulk()"
              :ok-title="t('accept bulk')" :cancel-title="t('cancel bulk')" :title="`${t('Apply Bulk Changes')}`">
          {{t('Are you sure')}}
        </b-modal>

        <b-modal id="big_bulk" ref="big_bulk" :ok-title="t('Accept')" :cancel-title="t('Cancel')" :title="`${t('Apply Bulk Changes')}`">
          {{t('bulk_too_big')}}
        </b-modal>


        <job-progress  :jobId="jobId" :show="showInformationJob" :timeConfiguration="times" @close="hideInformationJob"/>
      </b-card>
  </div>
</template>

<script>
import SelectTable from "./components/SelectTable.vue";
import YesNoIcon from '@/components/Tables/Formatters/YesNoIcon';
import {Tag} from 'element-ui';
import IdentifiersFormatter from './components/IdentifiersFormatter.vue';
import StatusFormatter from './components/StatusFormatter.vue';
import {closeFilterTag, getQuery, getAgentOptionsFiltered} from '../../../../util/selecTableRSUtil';
import JobProgress from './TroubleShooting/JobProgress.vue';
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";
import _ from "lodash";

export default {
  name: "rs-troubleshooting",
  components: {
    'el-tag': Tag,
    'select-table': SelectTable,
    'identifiers-formatter': IdentifiersFormatter,
    'status-formatter': StatusFormatter,
    'job-progress': JobProgress,
    PageTitleCard
  },
  data() {
    return {
      updating: false,
      routers: [],
      query: {
        filter: {},
        sort: 'asc',
        limit: 10
      },
      self_query: {},
      bulk: {
        safe: false,
        reboot: false,
        disable_agent: false,
        update_target: false,
        version_selected: '',
        routers_Selected: [],
      },
      search_names: {},
      currentPage:1,
      nextPage: 0,
      next_id: null,
      prev_id: null,
      changingPage: false,
      rowsSelected: 0,
      query_count: 0,
      showInformationJob: false,
      isAllButtonSelected: false,
      times: {
        agent: 0,
        reboot: 0,
        safe: 0
      },
      columns: [
       { prop: 'router_name', label: this.t('Device ID'), minWidth: 100},
      { prop: 'expand',  type: 'expand', minWidth: 5},
       { prop: 'rs_id', label: this.t('RouterSecure ID'), formatter: IdentifiersFormatter, minWidth: 140},
       { prop: 'account', label: this.t('Account'), minWidth: 100 },
       { prop: 'router_model', label: this.t('Router Model'), minWidth: 100 },
       { prop: 'firmware', label: this.t('Router Firmware'), minWidth: 100, },
       { prop: 'agent_version', label: this.t('Active Agent Version'), minWidth: 100 },
       { prop: 'target_version', label: this.t('Target Agent Package'), minWidth: 100 },
       { prop: 'status', label: this.t('status'), minWidth: 80, formatter: YesNoIcon, align: 'center'},
       { prop: 'state', label: this.t('state'), minWidth: 100, formatter: StatusFormatter, align: 'center'}
      ],
      actions: {
        minWidth: 100,
        label: this.t('Actions'),
        fixed: 'right',
        items: [
            {type: 'warning', icon: 'now-ui-icons business_badge', event: 'item-edit', action: this.t('Edit'), visible_for_permissions: [{actions: ["PATCH"], path: "/accounts/*/devices/*"}]},
            {type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'item-remove', action: this.t('Remove'), confirm: true, visible_for_permissions: [{actions: ["DELETE"], path: "/accounts/*/devices/*"}]},
        ]
      },
      search_options: [
        {value: 'router_id', key: 'router_id', label: this.t('Device Id')},
        {value: 'account_id', key: 'account', label: this.t('Account Id')},
        {value: 'mac_address', key: 'mac', label: this.t('mac')}
        // HIDE FOR VERSION 30.1
        // {value: 'serial_number', key: 'serial', label: this.t('serial')}
      ],
      searchRestrictions: { mac_address: '^[a-fA-F0-9:]+$' },
      searchValidations: { mac_address: '^[a-fA-F0-9:]{17}$|[a-fA-F0-9]{12}$' }
    };
  },
   mounted() {
      this.$store.dispatch('routerSecure/troubleshooting/getAdvancedConfiguration').then((configuration) => {
        this.times = {
          reboot:  configuration.reset_reboot_loop_min_time_between_actions_sec,
          safe: configuration.reset_safe_mode_min_time_between_actions_sec,
          agent: configuration.set_agent_package_min_time_between_actions_sec
        }
      })
      this.$store.dispatch('routerSecure/troubleshooting/getAgentPackages')
      this.refresh(0);
      this.getFieldNames('router_model')
      this.getFieldNames('firmware_version')
      this.getFieldNames('current_agent_package_id')
      this.getFieldNames('current_agent_version_id')
      this.getFieldNames('target_agent_package_id')
  },
 computed: {
    realColumns: {
      get(){
        return this.columns
      },
    },
    rows(){
      let ret = [];
      this.routers.forEach((value, key) => {
        ret.push({...value, target_version: this.getTargetVersion(value.target_agent_package_id)})
      })

       let arRet = this.search_pagination
                ? ret
                : Array.from(ret).sort( (a,b) => {
                    try{
                        return a.name.toUpperCase() > b.name.toUpperCase() ? 1: -1
                    }
                    catch(err){
                        return -1
                    }
                })
      return arRet
    },
    search_field_value: {
      get(){
        if (this.query && this.query.field_value)
          return {field: 'router_id', value: this.query.field_value}
        else
          return {field: 'router_id', value: ''}
      },
      set(newValue){
        this.handleSearch(newValue)
      }
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.prev_id,
          next: !!this.next_id,
          currentPage: this.currentPage
        }
      }
    } ,
    bulk_size: {
      get() {
        return this.$store.getters['routerSecure/troubleshooting/advancedConfigValue']("router_command_max_job_size")
      }
    },
    jobId: {
      get() {
        return this.$store.getters['routerSecure/troubleshooting/job_id']
      }
    },
    agent_options: {
      get( ) {
        const agents = this.$store.getters['routerSecure/troubleshooting/agent_packages']
        return  getAgentOptionsFiltered(agents, this.query.filter)
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    reload() {
      if (this.self_query) {
        this.changingPage = false;
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
    refresh(wait) {
      let _vm = this;
      this.updating = true;
      this.$store.dispatch('routerSecure/troubleshooting/getRouters', {wait: wait, query: getQuery(this.query, this.search_names)})
      .then( res => {
        _vm.routers = res.items
        _vm.query_count = res.query_count
        delete _vm.query.prev_id
        delete _vm.query.next_id
        _vm.self_query = res.self
        _vm.next_id = res.next_id
        _vm.prev_id = res.prev_id
        if (this.changingPage) {
          this.currentPage += this.nextPage
        }
        this.updating = false
      })
      .catch(err => { this.updating = false})
    },

    getTargetVersion(target_agent_package_id){
      if (!target_agent_package_id)
        return this.t('default')

      const agents = this.$store.getters['routerSecure/troubleshooting/agent_packages']
      if (!!agents){
        let agent = agents.filter( (item) => item.agent_package_id == target_agent_package_id )
        if (agent && agent.length > 0){
          return agent[0].agent_version_id
        }
        else{
          return this.t('default')
        }
      }
      else
        return this.t('default')
    },

    handleEdit(data) {
      this.$router.push( {path: '/account_management/accounts/edit/' + encodeURIComponent(data.row.account) + '/devices/edit/' + encodeURIComponent(data.row.router_id)})
    },
    handleRemove(data) {
      this.updating = true
      this.$store.dispatch('accountManagement/devices/removeDevice', {account_id: data.row.account, id: data.row.router_id} ) // account_id?
       .then(() => {
        this.reload()
        this.updating = false
      })
      .catch(err => { this.updating = false})

    },
    handleNextPage() {
      if (this.query){
        ['prev_id', 'prev_name'].forEach( item => {
          if ( this.query[item])
            delete this.query[item]
        });
        this.prev_id = null;
        if ( this.next_id){
          this.query.next_id = this.next_id
          this.nextPage = 1
          this.changingPage = true;
          this.refresh()
        }
      }
    },
    handlePrevPage() {
      if (this.query){
        ['next_id', 'next_name'].forEach( item => {
          if ( this.query[item])
            delete this.query[item]
        });
        this.next_id = null;
        if ( this.prev_id){
          this.query.prev_id = this.prev_id
          this.nextPage = -1
          this.changingPage = true;
          this.refresh()
        }
      }
    },
    handlePagination(value) {
      this.currentPage = 1;
      this.nextPage = 0;
      this.changingPage = false;
      this.self_query.limit = value;
      ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
          if ( this.self_query[item])
             delete this.self_query[item]
      });
      this.reload()
    },
    handleRows(value) {
      this.rowsSelected = value
    },
    handleSearch: _.debounce( function (data) {
      this.clearSelection()
      this.currentPage = 1;
      this.nextPage = 0;
      if (data) {
        if (data.value === '') {
          this.handleClearSearch()
        } else {
          this.search_names = data
        }
      }
      this.refresh();

    }, 500),
    showBulkModal(data) {
         if (!this.isAllButtonSelected) {
           this.bulk.routers_Selected = data
         }
        this.$refs.bulk_modal.show()

    },
   async handleApplyBulk(){
       this.$refs.confirm_bulk.show()
    },

    getFieldNames(name) {
       this.$store.dispatch('routerSecure/troubleshooting/getFields', name)
    },
    clearSelection(){
      this.handleRows(0)
      this.handleButtonSelectAll(false)
    },
    // Filter methods
    changeFilter(data) {
      this.clearSelection()
      this.query = {
        ...this.query,
        filter: {...data}
      }
      this.currentPage = 1;
      this.nextPage = 0;
      this.refresh()
    },
    handleClearFilter() {
      this.clearSelection()
      this.query = {
        ...this.query,
        filter: {}
      }
      this.refresh()
    },
    handleCloseTagFilter(tag) {
      this.clearSelection()
      this.query = {
        ...this.query,
        filter: closeFilterTag(this.query.filter, tag)
      }
      this.currentPage = 1;
      this.nextPage = 0;
      this.refresh()
    },
    handleClearSearch(){
      this.clearSelection()
      this.search_names = {};
      ['router_id', 'account_id', 'mac_address', 'serial_number'].map(field => {
        if(this.query && this.query.filter && this.query.filter[field]) {
          delete this.query.filter[field]
        }
      })
    },
    handleButtonSelectAll(selectAll) {
      this.isAllButtonSelected = selectAll
    },
    setBulk() {
      let queryFilter = {};
      Object.keys(this.query.filter).forEach((key, index) => {
          switch (key) {
            case "agent_version":
              if (this.query.filter.agent_version && this.query.filter.agent_version.length) {
                queryFilter.current_agent_version_id = this.query.filter.agent_version;  
              }
              break;
              case "attributes":
                if (this.query.filter.attributes && this.query.filter.attributes.length) {
                  queryFilter.attributes = this.query.filter.attributes;  
                }
                break;
              case "firmware":
                if (this.query.filter.firmware && this.query.filter.firmware.length) {
                  queryFilter.firmware_version = this.query.filter.firmware;  
                }
                break;
              case "more":
                if (this.query.filter.more && this.query.filter.more.length) {
                  if (this.query.filter.more.releaseBoot && this.query.filter.more.releaseBoot.apply) {
                    queryFilter.last_time_reset_reboot_loop = this.getDelta(this.query.filter.more.releaseBoot.frequency); 
                  }
                  if (this.query.filter.more.releaseSafe && this.query.filter.more.releaseSafe.apply) {
                    queryFilter.last_time_reset_safe_mode = this.getDelta(this.query.filter.more.releaseSafe.frequency); 
                  }
                  if (this.query.filter.more.upgradedVersion && this.query.filter.more.upgradedVersion.apply) {
                    queryFilter.last_time_update_target_agent_package = this.getDelta(this.query.filter.more.upgradedVersion.frequency); 
                  }
                }
                break;
              case "reboot":
                if (this.query.filter.reboot && this.query.filter.reboot == 'Active') {
                  queryFilter.reboot_loop = true;  
                } else if (this.query.filter.reboot && this.query.filter.reboot != 'All'){
                  queryFilter.reboot_loop = false; 
                }
                break;
              case "router_model":
                if (this.query.filter.router_model && this.query.filter.router_model.length) {
                  queryFilter.router_model = this.query.filter.router_model;  
                }
                break;
              case "safe":
                if (this.query.filter.safe && this.query.filter.safe == 'Active') {
                  queryFilter.safe_mode = true;  
                } else if (this.query.filter.safe && this.query.filter.safe != 'All') {
                  queryFilter.safe_mode = false;  
                }
                break;  
              case "status":
                if (this.query.filter.status && this.query.filter.status == 'Active') {
                  queryFilter.status = true;  
                } else if (this.query.filter.status && this.query.filter.status != 'All') {
                  queryFilter.status = false;
                }
                break;
              case "target_version":
                if (this.query.filter.target_version && this.query.filter.target_version.length) {
                  queryFilter.target_agent_package_id = this.query.filter.target_version;
                }
                break;  
            default:
              break;
          }
      });

      if (this.bulk) {
        if(this.bulk.reboot || this.bulk.safe) {
          
           let bulkquery = {
              reset_safe_mode: this.bulk.safe,
              reset_unstable:  this.bulk.reboot,
              router_selection: {
                filter: queryFilter
                // limit, next_id, prep_id
              }
          }

          if (this.isAllButtonSelected) {
            bulkquery.router_selection.limit =  this.bulk_size === 0 || (this.query_count <=  this.bulk_size) ? this.query_count  : this.bulk_size
          } else {
            bulkquery.router_selection.filter.router_id  = this.bulk.routers_Selected;
            bulkquery.router_selection.limit = this.bulk.routers_Selected.length;
          }

          if( bulkquery.router_selection.limit  > this.bulk_size )
            this.$refs.big_bulk.show()
          else 
            this.$store.dispatch('routerSecure/troubleshooting/resetStateStart', bulkquery).then(response => {
              this.startViewInformationJob()
            })

        } else {
          let bulkquery = {
            target_agent_package_id: this.bulk.version_selected,
            router_selection: {
              filter: queryFilter
              // limit, next_id, prep_id
            }
          }

          if (this.isAllButtonSelected) {
            bulkquery.router_selection.limit =  this.bulk_size === 0 || (this.query_count <=  this.bulk_size) ? this.query_count  : this.bulk_size
          } else {
            bulkquery.router_selection.filter.router_id  = this.bulk.routers_Selected;
            bulkquery.router_selection.limit = this.bulk.routers_Selected.length;
          }

          if( bulkquery.router_selection.limit  > this.bulk_size )
            this.$refs.big_bulk.show()
          else 
            this.$store.dispatch('routerSecure/troubleshooting/setAgentPackageStart', bulkquery).then(response => {
              this.startViewInformationJob()
            })
        }
      }
    },
    startViewInformationJob() {
       this.showInformationJob = true

    },
    hideInformationJob() {
        this.showInformationJob = false
    },
    getDelta(value) {
      let obj = {};
      if (value && value.period && value.time) {
        switch (value.period) {
          case "minutes":
            obj = {operator: "lte", delta: value.time}
            break;
          case "hours":
            obj = {operator: "lte", delta: (value.time * 60)}
            break;  
          case "days":
            obj = {operator: "lte", delta: (value.time * 24 * 60)}
            break;  
          case "weeks":
            obj = {operator: "lte", delta: (value.time * 7 * 24 * 60)}
            break;  
          case "months":
            obj = {operator: "lte", delta: (value.time * 30 * 7 * 24 * 60)}
            break;  
        
          default:
            break;
        }
      }
      return obj;
    }
  },
};
</script>

<style scope>
.button-descrip {
    height: 16px;
    font-size: x-small;
    color: #ffffff;
}

.button-icon {
    color: white;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    vertical-align: middle !important;
}
.margin-text {
  margin: 25px 0;
}

.attributes-tag {
	font-size: 14px;
	color: white;
  border-color: #D7D8DD;
	background-color: #383B57;
	padding: 8px 12px 8px 24px;
  height: 32px;
}

.white-content .attributes-tag {
	color: #3f4261;
  border-color: #3f4261;
	background-color:white;
}

.router_model {
  width: 14rem;
  float: left;
}

.router_attrs{
  float: left;
  margin-left: 1rem;
}
</style>
