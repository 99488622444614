<template>
	<b-row>
		<b-col xl="12" class="mb-2 ml-5">
					<el-checkbox class="mb-2 select-primary" v-model="filter.releaseBoot.apply" @change="change">{{`${filter.releaseBoot.apply ? t('reboot_last') : t('reboot') }`}}</el-checkbox>
				 <div class="frequency-container" v-if="filter.releaseBoot.apply">
					<input type="number" min="1" v-model.number="filter.releaseBoot.frequency.time" @change="change" class=" white-content el-input__inner upgraded-number">
					  <el-select
                class="select-primary upgraded-text"
                size="sm"
                :placeholder="this.t('Period')"
                v-model="filter.releaseBoot.frequency.period"
                @change="change"
                style="margin-top: -10px"
              >
                <el-option
                  v-for="option in periodOptions"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
					</div>		
		</b-col>
		<b-col xl="12" class="mb-2 ml-5">
				<el-checkbox class="mb-2 select-primary" v-model="filter.releaseSafe.apply" @change="change">{{`${filter.releaseSafe.apply ? t('safe_last') : t('safe') }`}}</el-checkbox>
			 <div class="frequency-container" v-if="filter.releaseSafe.apply" @change="change">
				<input type="number" min="1" v-model.number="filter.releaseSafe.frequency.time" @change="change" class="el-input__inner upgraded-number">
				  <el-select
              class="select-primary upgraded-text"
              size="sm"
              :placeholder="this.t('Period')"
              v-model="filter.releaseSafe.frequency.period"
              @change="change"
              style="margin-top: -10px"
            >
              <el-option
                v-for="option in periodOptions"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
				</div>		
		</b-col> 
		<b-col xl="12" class="mb-2 ml-5">
					<el-checkbox class="mb-2 select-primary" v-model="filter.upgradedVersion.apply" @change="change">{{`${filter.upgradedVersion.apply ? t('upgraded_last') : t('upgraded') }`}}</el-checkbox>
				 <div class="frequency-container" v-if="filter.upgradedVersion.apply">
					<input type="number" min="1" v-model.number="filter.upgradedVersion.frequency.time" @change="change" class="el-input el-input__inner upgraded-number">
					  <el-select
                class="select-primary upgraded-text"
                size="sm"
                :placeholder="this.t('Period')"
                v-model="filter.upgradedVersion.frequency.period"
                @change="change"
                style="margin-top: -10px"
              >
                <el-option
                  v-for="option in periodOptions"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
					</div>		
		</b-col>
	</b-row>
</template>

<script>
import {Select, Option, Input, Tag } from 'element-ui';

export default {
	name: 'more-filter',
	components: {
		'el-select': Select,
		'el-option': Option,
		'el-input' : Input,
		'el-tag': Tag,
	},
  props: {
    name: {
      type: String
    },
    filter: {
      type: Object,
    },
    periodOptions: {
      type: Array
    },
    handleOpen: {
      type: Function,
      default: () => {}

    }, 
    close: {
      type: Function,
      default: () => {}
		} ,
		show: {
			type: Boolean, 
			default: false
    },
    edit: {
      type: Function,
      default: () => {}
    }

  },
  methods: {
    change(event) {
      this.$emit('edit', event)
    }
  }  
}
</script>

<style lang="scss" scoped>

.frequency-container input {
	color: white;
}

.group .el-input__inner:focus {
 color: #32325d;
}

.white-content .frequency-container input {
	color: #32325d;
}

.white-content .group .el-input__inner:focus {
  color: #32325d;
} 

</style>