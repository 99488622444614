<template>
   <b-modal id="job-progress" size="lg" ref="job_progress" @hide="hideJobInformation">	
				<template #modal-header>
          <h3  class="modal-title white-content  mx-auto">{{t('Changes applied')}}</h3>
				 	<button type="button" aria-label="Close" @click="hideJobInformation" class="close">x</button>
        </template>
					<el-progress  :stroke-width="26" :percentage="percentage" :color="customColor" ></el-progress>
					<div class="text">{{`${processed} ${t('of')} ${total} ${t('routers processed')}`}} </div>
					<el-input class="input-area" type="textarea"  maxlength="10" :autosize="{ minRows: 6, maxRows: 6}" v-model="jobinfo"></el-input>
					<template  v-if="updated !== processed">
						<div class="container-message">
							<template v-if="action.includes('agent')">
								<p>{{t('compatible routers message')}} </p>
								<p>{{`${t('actions skiped message')} ${timeConfiguration ? timeConfiguration.agent : ''} ${t('secs')}`}} </p>
							</template>
							<template v-else>
								<p>{{`${t('reboot or safe message')} ${timeConfiguration ? timeConfiguration.reboot : ''} ${t('secs')}`}} </p>
							</template>
						</div>
					</template>	
				<template #modal-footer>
					<div class="button-container">
						<button v-if="isRunning"  type="button" class="btn btn-primary btn-block btn-round" @click="stopProgress"> {{t('Stop process')}} </button>
						<button v-else type="button" class="btn btn-primary btn-block btn-round" @click="hideJobInformation"> {{t('Ok')}} </button>
					</div>	
				</template>	
      </b-modal>  
</template>

<script>

export default {
	name: 'job-progress',
	components: {
		
	},
	data() {
		return {
			customColor: '#f96332',
			total: 0,
			processed: 0,
			updated: 0,
			jobinfo: '',
			status: '',
			action: ''
		}
	},
  props: {
	jobId: {
		type: String,
	},
    handleOpen: {
      type: Function,
      default: () => {}

    }, 
    close: {
      type: Function,
      default: () => {}
	} ,
	show: {
		type: Boolean,
		default: false
	},
	timeConfiguration: {
		type: Object
	}

	},
	watch: {
		show(newValue) {
			if (newValue) {
				this.startProgress()
				this.$refs.job_progress.show()

			} else {
				this.$refs.job_progress.hide()
			}
		},	
	},

	methods: {
		hideJobInformation() {
			this.$emit('close')
		},
		startProgress() {
			let _vm = this;
			let interval = 1000;
			let stopped = false;
			_vm.processed = 0;
			_vm.updated = 0;
			_vm.jobinfo = '';
			_vm.status = '';
			setInterval((async () => {
				if ((_vm.status === "" || _vm.status === "running") && stopped === false) {
					try {
						//console.log('start progress....', _vm.jobId)
						const data =  await _vm.$store.dispatch('routerSecure/troubleshooting/getJob', _vm.jobId)
						_vm.status = data.status
      					_vm.total = data.total
						_vm.processed = data.processed
						_vm.updated = data.updated
						_vm.action = data.action
						_vm.jobinfo = data.logs && data.logs.length ? data.logs : ''
					} catch (error) {
						console.log('error:', error)
						clearInterval(interval)
						stopped = true
					}
				} else {
					clearInterval(interval);
					stopped = true
				}
			}), interval);
		},
		stopProgress() {
			this.$store.dispatch('routerSecure/troubleshooting/stopJob', this.jobId)
		},
		mounted() {
		}
	},
	computed: {
		percentage: {
			get() {
				if (this.total === 0) {
					return 0
				}
				return parseInt(this.processed * 100 / this.total)
			}
		},
		isRunning: {
			get() {
				return this.status==='running'
			}
		}
	}	
}
</script>

<style lang="scss" scoped>

.container{
	margin: 0 100px !important;
}

.modal-title {
	margin-bottom: 25px;
}

.button-container {
	position: relative;
  left: 40%;	
}

.modal-body {
	margin: 0 55px 35px 55px !important;
}

.text {
	margin: 15px 0;
}

.el-textarea__inner {
	min-height: 150px !important;
  overflow-y: scroll;
}

.el-progress {
	display: flex;
}

.el-progress__text {
    line-height: 1.75rem;
}	 

.container-message {
	margin: 20px 25px 0;
}

</style>