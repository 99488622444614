<template>
  <b-overlay
    :show="updating"
    rounded
    opacity="0"
    spinner-small
    spinner-variant="primary"
    class="select-table"
  >
    <b-card
      card-body-classes="table-full-width"
      class="select-table-card"
      :class="{ 'select-table-card_updating': updating }"
      no-footer-line
    >
      <b-container slot="header" class="col-12">
        <b-row align-h="between">
          <span class="search-text">{{ t("Search by *") }}</span>
          <b-col
            style="padding-left: 0"
            class="text-left col-10"
            align-self="center"
          >
            <fg-input
              class="my-2"
              v-if="
                searchable &&
                search &&
                search.length > 0 &&
                (tableData.length > 0 || serverpagination)
              "
            >
              <template v-slot:addonLeft v-if="search.length > 1">
                <el-select
                  class="select-primary"
                  style="max-width: 175px"
                  size="large"
                  :placeholder="t('Search by')"
                  v-model="searchField"
                  @change="search_change"
                >
                  <el-option
                    v-for="option in search"
                    class="select-primary"
                    :value="option.value || option"
                    :label="option.label || option"
                    :key="option.key || option"
                  >
                  </el-option>
                </el-select>
              </template>
              <el-input
                type="search"
                clearable
                @input="search_keypress"
                prefix-icon="el-icon-search"
                style="max-width: 350px; padding-left: 7px"
                :placeholder="t('Search records')"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </fg-input>
          </b-col>
          <b-col class="text-right" align-self="center">
            <n-button
              v-if="withCreate"
              type="primary"
              size="md"
              style="min-width: 120px; max-width: 20%; float: right"
              round
              block
              @click.native="$emit('handle-new')"
              :visible_for_permissions="createButtonPermissions"
              >{{ t("Create new") }}</n-button
            >
          </b-col>
        </b-row>
        <b-row
          v-if="
            search &&
            search.length > 0 &&
            (tableData.length > 0 || serverpagination)
          "
        >
          <b-col class="partial_matches_alert" align-self="center">
            <p>
              * {{ t("search_fields") }}:
              <template v-for="(field, index) in search">
                {{ field.label || field }}
                <template v-if="index < search.length - 1">, </template>
              </template>
            </p>
          </b-col>
        </b-row>
        <b-row
          v-if="
            showPartialMatchText &&
            search &&
            search.length > 0 &&
            (tableData.length > 0 || serverpagination)
          "
        >
          <b-col class="partial_matches_alert" align-self="center">
            <span
              ><i>{{ t("partial_match_warning") }}</i></span
            >
          </b-col>
        </b-row>
        <!-- Selection component -->
        <selection-component
          :rows_selected="rows_selected"
          :queryCount="queryCount"
          :bulkSize="bulkSize"
          :isAllButtonSelected="isAllButtonSelected"
          @selection="toggleSelection"
          @show-modal="showFilterModal"
          @apply-changes="handleApplyChanges"
        />
        <!-- Filter label -->
        <filter-labels-component
          :currentFilter="currentFilter"
          @close="handleCloseTag"
          @clear-all="clearFilter"
        />

        <b-row class="hr-grey mb-2"></b-row>
      </b-container>
      <b-modal
        v-if="itemForAction"
        id="confirm"
        ref="modal"
        @ok="handleConfirm"
        @hidden="handleCancel"
        :title="t(itemForAction.action)"
        :ok-title="t('accept')"
        :cancel-title="t('cancel')"
      >
        <div v-if="itemForAction.text">{{ t(itemForAction.text) }}.</div>
        {{
          itemForAction.message === undefined
            ? t("Are you sure that you want to delete?")
            : t(itemForAction.message)
        }}
      </b-modal>

      <b-card-body class="router-card-body">
        <div ref="wrapper" v-if="tableData.length > 0">
          <div :key="tableKey">
            <el-table
              :show-header="showHeader"
              ref="myTable"
              style="width: 100%"
              :data="tableData"
              row-key="router_id"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                v-if="draggable"
                :render-header="
                  (h) => {
                    return h('i', {
                      class: 'now-ui-icons design_bullet-list-67',
                    });
                  }
                "
              >
                <div class="click-drag">
                  <i class="now-ui-icons arrows-1_minimal-up"></i>
                  <i class="now-ui-icons arrows-1_minimal-down"></i>
                </div>
              </el-table-column>
              <el-table-column
                type="selection"
                :selectable="(row, index) => !isAllButtonSelected"
                :reserve-selection="reserveSelection"
                role="checkbox"
                @click.native="handleTableCheck"
                width="60"
              >
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :type="column.type || ''"
                :key="t(column.label)"
                :min-width="column.minWidth"
                :width="column.width"
                :align="column.align || 'left'"
                :prop="column.prop"
                :label="t(column.label)"
                :sortable="
                  typeof column.sortable === 'boolean' ? column.sortable : false
                "
                :sort-method="column.sortMethod"
                :formatter="
                  typeof column.formatter === 'function'
                    ? column.formatter
                    : null
                "
              >
                <template
                  #default="{ row }"
                  v-if="typeof column.formatter !== 'function'"
                >
                  <template v-if="column.type === 'expand'">
                    <slot name="expand" v-bind:row="row" />
                  </template>
                  <template v-else-if="row[column.prop] === undefined">{{
                    column.default
                  }}</template>
                  <div
                    v-else-if="column.formatter"
                    :is="column.formatter"
                    :row="row"
                    :column="column"
                    @input="handleStatusChange($event)"
                  />
                  <template
                    v-else-if="editingRow && editingRow.row.id === row.id"
                  >
                    <input type="text" v-model="row[column.prop]" />
                  </template>
                  <template v-else-if="column.translateCells">{{
                    t(row[column.prop])
                  }}</template>
                  <template v-else>{{ row[column.prop] }}</template>
                </template>
              </el-table-column>
              <el-table-column
                v-if="hasExpandSlot && !hasExpandColumn"
                type="expand"
                width="1"
                align="left"
              >
                <template slot-scope="scope">
                  {{ row[column.prop] }}
                  <slot name="expand" v-bind:row="scope.row" />
                </template>
              </el-table-column>
              <el-table-column
                v-if="actions"
                :min-width="actions.minWidth || 335"
                :fixed="actions.fixed || 'right'"
                :label="t(actions.label) || 'Actions'"
              >
                <div slot-scope="props" class="table-actions">
                  <template
                    v-if="editingRow && editingRow.row.id === props.row.id"
                  >
                    <template v-for="item in actions.editItems">
                      <el-tooltip
                        :content="t(item.action)"
                        :open-delay="250"
                        :hide-after="1500"
                        :key="item.event"
                      >
                        <n-button
                          v-if="!item.confirm"
                          :id="'selectedTable-action-' + item.action"
                          @click.native="
                            $emit(item.event, {
                              index: props.$index,
                              row: props.row,
                            })
                          "
                          :type="item.type || 'primary'"
                          :size="item.size || 'sm'"
                          round
                          icon
                          :disabled="
                            item.disabledIf ||
                            buttonDisabled(editingRow, item.event)
                          "
                          :visible_for_permissions="
                            item.hasOwnProperty('visible_for_permissions')
                              ? item.visible_for_permissions
                              : []
                          "
                          v-b-modal.confirm
                        >
                          <svgicon
                            v-if="item.isSVGIcon"
                            :original="item.isIconOriginal"
                            :icon="item.icon"
                            :class="item.iconClass || ''"
                            :width="item.iconWidth || '14'"
                            :height="item.iconHeight || '14'"
                          />
                          <i v-else :class="item.icon" />
                        </n-button>
                        <n-button
                          v-if="item.confirm"
                          :id="'selectedTable-action-' + item.action"
                          @click.native="
                            itemForAction = {
                              event: item.event,
                              action: item.action,
                              data: { index: props.$index, row: props.row },
                            }
                          "
                          :type="item.type || 'primary'"
                          :size="item.size || 'sm'"
                          round
                          icon
                          :disabled="
                            item.disabledIf ||
                            buttonDisabled(editingRow, item.event)
                          "
                          :visible_for_permissions="
                            item.hasOwnProperty('visible_for_permissions')
                              ? item.visible_for_permissions
                              : []
                          "
                          v-b-modal.confirm
                        >
                          <svgicon
                            v-if="item.isSVGIcon"
                            :original="item.isIconOriginal"
                            :icon="item.icon"
                            :class="item.iconClass || ''"
                            :width="item.iconWidth || '14'"
                            :height="item.iconHeight || '14'"
                          />
                          <i v-else :class="item.icon" />
                        </n-button>
                      </el-tooltip>
                    </template>
                  </template>
                  <template v-else v-for="item in actions.items">
                    <el-tooltip
                      v-if="
                        item.visibleIf === undefined ||
                        item.visibleIf(props.row)
                      "
                      :content="t(item.action)"
                      :open-delay="250"
                      :hide-after="1500"
                      :key="item.event"
                    >
                      <n-button
                        v-if="!item.confirm"
                        :id="'selectedTable-action-' + item.action"
                        @click.native="emitClick(item, props)"
                        :type="item.type || 'primary'"
                        :size="item.size || 'sm'"
                        round
                        icon
                        :disabled="
                          item.disabledIf ||
                          buttonDisabled(props.row, item.event)
                        "
                        :visible_for_permissions="
                          item.hasOwnProperty('visible_for_permissions')
                            ? item.visible_for_permissions
                            : []
                        "
                        v-b-modal.confirm
                      >
                        <svgicon
                          v-if="item.isSVGIcon"
                          :original="item.isIconOriginal"
                          :icon="item.icon"
                          :class="item.iconClass || ''"
                          :width="item.iconWidth || '14'"
                          :height="item.iconHeight || '14'"
                        />
                        <i v-else :class="item.icon" />
                      </n-button>
                      <n-button
                        v-if="item.confirm"
                        :id="'selectedTable-action-' + item.action"
                        @click.native="
                          itemForAction = {
                            event: item.event,
                            action: item.action,
                            message: item.message,
                            data: { index: props.$index, row: props.row },
                          }
                        "
                        :type="item.type || 'primary'"
                        :size="item.size || 'sm'"
                        round
                        icon
                        :disabled="
                          item.disabledIf ||
                          buttonDisabled(props.row, item.event)
                        "
                        :visible_for_permissions="
                          item.hasOwnProperty('visible_for_permissions')
                            ? item.visible_for_permissions
                            : []
                        "
                        v-b-modal.confirm
                      >
                        <svgicon
                          v-if="item.isSVGIcon"
                          :original="item.isIconOriginal"
                          :icon="item.icon"
                          :class="item.iconClass || ''"
                          :width="item.iconWidth || '14'"
                          :height="item.iconHeight || '14'"
                        />
                        <i v-else :class="item.icon"></i>
                      </n-button>
                    </el-tooltip>
                  </template>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <empty-state v-else :updating="updating" />
      </b-card-body>

      <div
        v-if="tableData.length > 0"
        slot="footer"
        class="col-12 select-table-card__pagination-wrapper"
      >
        <div class="select-table-card__pagination-item mb-4 mb-sm-0">
          <p
            id="selectedTable-server-pag-summary"
            class="card-category"
            v-if="serverpagination"
          >
            {{ serverPaginationEntriesLabel }}
          </p>
          <p id="selectedTable-pag-summary" class="card-category" v-else>
            {{ t("Showing") }} {{ from + 1 }} {{ t("to") }} {{ to }}
            {{ t("of") }} {{ total }} {{ t("entries") }}
          </p>
        </div>
        <div class="select-table-card__pagination-item order-3 order-sm-1">
          <span class="card-category pr-2">{{ t("Results per page") }}:</span>
          <el-select
            id="selectedTable-pag-selector"
            class="select-primary select-table-card__perpage-select"
            v-model="perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="(item, i) in pagination.perPageOptions"
              :id="'selectedTable-pag-selector-opt-' + i"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div
          class="
            select-table-card__pagination-item
            order-2 order-sm-1
            mb-4 mb-sm-0
          "
        >
          <n-pagination
            v-if="!serverpagination"
            class="pagination-no-border mb-0"
            v-model="pagination.currentPage"
            ref="pagination"
            :per-page="pagination.perPage"
            :total="total"
          />
          <n-pagination-server
            v-else
            class="pagination-no-border mb-0"
            v-model="currentPage"
            :updating="updating"
            :per-page="perPage"
            :prev="serverpagination.prev"
            :next="serverpagination.next"
          />
        </div>
      </div>
      <!-- Modal for RS troubleshooting filter -->
      <advanced-filtering
        ref="filter_modal"
        :filter="filter"
        :show="showAdvancedModal"
        @close="hideFilterModal"
        @save="handleApplyFilter"
        @reset="handleResetMoreFilter"
      />
    </b-card>
    <span v-if="secsToRefresh > 0" class="countdown-timer"
      >{{ t("refresh-on") }} {{ countDownMinutes }}:{{ countDownSeconds }}</span
    >
  </b-overlay>
</template>

<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  Input,
  Tag,
  Button,
  RadioGroup,
  Radio,
} from "element-ui";
import Fuse from "fuse.js";
import sortable from "sortablejs";
import EmptyState from "@/components/EmptyState.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationServer from "@/components/PaginationServer.vue";
import AdvancedFiltering from "../TroubleShooting/AdvancedFiltering.vue";
import Switch from "@/components/Switch.vue";
import GeneralMixin from "@/components/AsmConfigurations/general/GeneralMixin";
import {
  filterTroubleshootTags,
  ROLE_TAG,
  DEFAULT_FILTER,
  clearMoreTagFilterValues,
  getStatus,
} from "@/util/selecTableRSUtil";
import BarChart from "../../../../../components/Charts/BarChart.vue";
import SelectionComponent from "../TroubleShooting/SelectionComponent.vue";
import FilterLabelComponent from "../TroubleShooting/FilterLabelsComponent.vue";

import { mapGetters } from "vuex";

export default {
  name: "select-table",
  components: {
    "el-select": Select,
    "el-option": Option,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-input": Input,
    "el-tag": Tag,
    "el-button": Button,
    "empty-state": EmptyState,
    "n-pagination": Pagination,
    "n-pagination-server": PaginationServer,
    "n-switch": Switch,
    "el-radio": Radio,
    "el-radio-group": RadioGroup,
    "advanced-filtering": AdvancedFiltering,
    "selection-component": SelectionComponent,
    "filter-labels-component": FilterLabelComponent,
    BarChart,
  },
  mixins: [GeneralMixin],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    actions: {
      type: Object,
      default: null,
    },
    search: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    serverpagination: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: () => {},
    },
    editingRow: {
      type: Object,
      default: () => null,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    handleDrag: {
      type: String,
      default: ".click-drag",
    },
    animateDrag: {
      type: Number,
      default: 100,
    },
    withCreate: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    searchRestrictions: {
      type: Object,
      default: () => {},
    },
    searchValidations: {
      type: Object,
      default: () => {},
    },
    translateCells: {
      type: Boolean,
      default: false,
    },
    createButtonPermissions: {
      type: Array,
      default: () => [],
    },
    buttonDisabled: {
      type: Function,
      default: () => false,
    },
    secsToRefresh: {
      type: Number,
      default: 0,
    },
    rowsSelected: {
      type: Number,
      default: 0,
    },
    queryCount: {
      type: Number,
      default: 0,
    },
    bulkSize: {
      type: Number,
      default: 0,
    },
    isAllButtonSelected: {
      type: Boolean,
      default: false,
    },
    reserveSelection: {
      type: Boolean,
      default: false,
    },
    showPartialMatchText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pagination: {
        perPage: null,
        currentPage: 1,
        perPageOptions: [5, 50, 100, 250],
        total: 0,
      },
      searchField: "",
      searchQuery: "",
      tableData: [],
      tableColumns: [],
      searchedData: [],
      fuseSearch: null,
      itemForAction: null,
      tableKey: 0, // For draggable
      sortabled: false, // For draggable
      countDown: 0,
      showAdvancedModal: false,
      currentFilter: [], // For troubleshooting modal
      filter: {
        ...DEFAULT_FILTER,
        period_options: [
          { value: "minutes", label: this.t("Minutes") },
          { value: "hours", label: this.t("Hours") },
          { value: "days", label: this.t("Days") },
          { value: "weeks", label: this.t("Weeks") },
          { value: "months", label: this.t("Months") },
        ],
      },
      isChangePage: false,
    };
  },
  mounted() {
    if (this.value && this.value.field) {
      this.searchField = this.value.field;
    }

    if (!this.serverpagination) {
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.computedSearch,
        threshold: 0.3,
      });
    }

    this.draggable && this.makeTableSortAble();
    if (this.secsToRefresh > 0) {
      this.countDown = this.secsToRefresh;
      this.countDownTimer();
    }
  },
  updated() {
    this.draggable && !this.sortabled && this.makeTableSortAble();
  },
  created() {
    this.pagination.perPage = this.itemsPerPage;
    this.tableData = this.rows;
    this.tableColumns = this.columns;
  },
  watch: {
    getLanguage(newValue, oldValue) {
      this.currentFilter = filterTroubleshootTags(this.filter);
      this.$emit("apply-filter", this.filter);
    },
    rows(newValue, oldValue) {
      this.tableData = newValue;
      this.searchedData = newValue;

      if (!this.serverpagination) {
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.computedSearch,
          threshold: 0.3,
        });

        if (this.searchQuery) {
          this.searchedData = this.fuseSearch.search(this.searchQuery);
        }
      } else {
        this.searchedData = newValue;
        if (
          this.searchedData.length == 0 &&
          this.serverpagination.currentPage > 1
        ) {
          // empty page after removing an item, maybe there are results on previous pages
          if (!this.serverpagination.prev && !this.serverpagination.next) {
            // backend can't retrieve records after the (new) last one, reset the query to display first page
            this.$emit("input", {
              field: this.searchField,
              value: this.searchQuery,
            });
          }
        }
      }
    },

    columns(newValue, oldValue) {
      this.tableColumns = this.columns;
    },

    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      if (value.length > 0 && this.searchField in this.searchValidations) {
        let regx = new RegExp(this.searchValidations[this.searchField]);
        if (!regx.test(value)) return false;
      }
      this.$emit("input", { field: this.searchField, value });
      if (!this.serverpagination) {
        let result = this.tableData;
        this.pagination.currentPage = 1;
        if (value !== "") {
          result = this.fuseSearch.search(value);
        }
        this.searchedData = result;
      }
    },

    searchField(value) {
      this.$emit("input", { field: value, value: this.searchQuery });
      if (!this.serverpagination) {
        this.searchedData = this.fuseSearch.search(this.searchQuery);
      }
    },

    // For draggable
    tableKey() {
      this.$nextTick(() => {
        this.makeTableSortAble();
        this.keepWrapperHeight(false);
      });
    },

    itemsPerPage(value) {
      this.pagination.perPage = value;
    },
  },
  computed: {
    ...mapGetters("settings", ["getLanguage"]),
	rows_selected: {
		get() {
			if (!this.rowsSelected && this.$refs.myTable)
				this.$refs.myTable.clearSelection();

			return this.rowsSelected
		},
		set(newValue){
	      this.$emit("number-rows-selected", newValue);
		}
	},

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    computedSearch() {
      return this.search.map((field) => field.value || field);
    },

    queriedData() {
      if (this.serverpagination) {
        return this.tableData;
      }

      const result =
        this.searchedData.length > 0 || this.searchQuery
          ? this.searchedData
          : this.tableData;
      return result.slice(this.from, this.to);
    },
    to() {
      if (this.serverpagination) {
        if (this.serverpagination.next_id)
          return this.from + this.pagination.perPage;
        else return this.from + this.tableData.length - 1;
      } else {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      }
    },
    from() {
      if (this.serverpagination)
        return this.pagination.perPage * (this.currentPage - 1) + 1;
      else return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      if (this.serverpagination) {
        return this.tableData.length;
      }

      return this.searchQuery !== ""
        ? this.searchedData.length
        : this.tableData.length;
    },
    sort: function (a, b) {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    },
    currentPage: {
      get() {
        return this.serverpagination.currentPage;
      },
      set(newValue) {
        if (newValue < this.serverpagination.currentPage) this.$emit("prev");
        if (newValue > this.serverpagination.currentPage) this.$emit("next");
      },
    },
    perPage: {
      get() {
        return this.pagination.perPage;
      },
      set(newValue) {
        // if (!this.isChangePage) this.isChangePage = true
        this.pagination.perPage = newValue;
        this.pagination.currentPage = 1;
        this.$emit("pagination", newValue);
      },
    },
    serverPaginationEntriesLabel() {
      let lessLength =
        this.rows.length < this.perPage &&
        !this.updating &&
        this.currentPage === 1
          ? `${this.t("of")} ${this.rows.length}`
          : "";
      return `${this.t("Showing")} ${this.t("entries")} ${this.from} ${this.t(
        "to"
      )} ${this.to} ${lessLength}`;
    },
    hasExpandSlot() {
      return !!this.$slots.expand || !!this.$scopedSlots.expand;
    },
    hasExpandColumn() {
      return this.columns.findIndex((column) => column.type === "expand") >= 0;
    },
    countDownMinutes() {
      return Math.floor((this.countDown % 3600) / 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
    },
    countDownSeconds() {
      return Math.floor((this.countDown % 3600) % 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
    },
    tagsSelected() {
      this.setTagsFiltered();
      return this.currentFilter;
    },
  },
  methods: {
    emitClick(item, props) {
      this.$emit(item.event, { index: props.$index, row: props.row });
    },
    search_change: function (value) {
      this.searchQuery = "";
      this.$emit("search-clear");
    },
    search_keypress: function (value) {
      if (this.searchRestrictions) {
        if (this.searchField in this.searchRestrictions) {
          let regx = new RegExp(this.searchRestrictions[this.searchField]);
          if (!regx.test(value)) {
            this.searchQuery = this.searchQuery.substring(
              0,
              this.searchQuery.length - 1
            );
          }
        }
      }
    },
    handleConfirm() {
      this.$emit(this.itemForAction.event, this.itemForAction.data);
      this.itemForAction = null;
    },
    handleCancel() {
      this.itemForAction = null;
    },
    handleStatusChange(event) {
      this.$emit("status-change", event);
    },
    makeTableSortAble() {
      if (this.$refs.myTable) {
        const myTable = this.$refs.myTable.bodyWrapper.querySelector("tbody");
        sortable.create(myTable, {
          handle: this.handleDrag,
          animation: this.animateDrag,
          onEnd: ({ newIndex, oldIndex }) => {
            this.keepWrapperHeight(true);
            this.tableKey = Math.random();
            let search = this.searchQuery !== "";
            let newIndexInPage =
              (this.pagination.currentPage - 1) * this.pagination.perPage +
              newIndex;
            let oldIndexInPage =
              (this.pagination.currentPage - 1) * this.pagination.perPage +
              oldIndex;
            this.$emit("handle-move", {
              newIndex: newIndexInPage,
              oldIndex: oldIndexInPage,
              search,
              reset: true,
            });
          },
          onChange: ({ newIndex, oldIndex }) => {
            let newIndexInPage =
              (this.pagination.currentPage - 1) * this.pagination.perPage +
              newIndex;
            let oldIndexInPage =
              (this.pagination.currentPage - 1) * this.pagination.perPage +
              oldIndex;
            let search = this.searchQuery !== "";
            this.$emit("handle-while-move", {
              newIndex: newIndexInPage,
              oldIndex: oldIndexInPage,
              search,
            });
            if (
              newIndex == this.pagination.perPage - 1 &&
              newIndexInPage < this.total - 1
            ) {
              // If item is dragged to last position on current page and there are more pages
              this.$emit("handle-dragged-to-last-in-page", {
                perPage: this.pagination.perPage,
              });
            } else if (newIndex == 0 && newIndexInPage > 0) {
              // If item is dragged to first position on current page and current page is not the first one
              this.$emit("handle-dragged-to-first-in-page", {
                perPage: this.pagination.perPage,
              });
            }
          },
          onStart: ({ oldIndex }) => {
            let oldIndexInPage =
              (this.pagination.currentPage - 1) * this.pagination.perPage +
              oldIndex;

            // Identify if the dragged element is the last one and it is unique on the page
            let isLastElement = oldIndexInPage === this.total - 1;
            let isUniqueOnPage = this.total % this.pagination.perPage === 1;

            let search = this.searchQuery !== "";
            let itemsPerPage = this.pagination.perPage;
            this.$emit("handle-start-drag", {
              oldIndex: oldIndexInPage,
              search,
              perPage: itemsPerPage,
            });
            if (isLastElement && isUniqueOnPage) {
              // If the dragged element is the last one and it is unique on the page
              this.$emit("handle-dragged-to-first-in-page", {
                lastAndUnique: true,
                perPage: this.pagination.perPage,
              });
            } else if (
              oldIndex === this.pagination.perPage - 1 &&
              oldIndexInPage < this.total - 1
            ) {
              // If the dragged element is the last one on current page and there are more pages
              this.$emit("handle-dragged-to-last-in-page", {
                perPage: this.pagination.perPage,
              });
            } else if (oldIndex === 0 && oldIndexInPage > 0) {
              // If the dragged element is the first one on current page and current page is not the first one
              this.$emit("handle-dragged-to-first-in-page", {
                perPage: this.pagination.perPage,
              });
            }
          },
        });
        this.sortabled = true;
      }
    },
    keepWrapperHeight(keep) {
      // eslint-disable-next-line prefer-destructuring
      const wrapper = this.$refs.wrapper;
      if (keep) {
        wrapper.style.minHeight = `${wrapper.clientHeight}px`;
      } else {
        wrapper.style.minHeight = "auto";
      }
    },
    buildSearcher() {
      let searcher = new Fuse(this.tableData, {
        keys: this.computedSearch,
        threshold: 0.1,
        ignoreLocation: true,
      });

      return searcher;
    },
    loadNextPage() {
      this.$refs.pagination.nextPage();
    },
    loadPrevPage() {
      this.$refs.pagination.prevPage();
    },
    countDownTimer() {
      setInterval(() => {
        this.countDown -= 1;
        if (this.countDown === 0) {
          this.countDown = this.secsToRefresh;
          this.$emit("reload");
        }
      }, 1000);
    },
    /**
     * methods for advanced filtering troubleshooting modal
     *
     */
    showFilterModal() {
      this.showAdvancedModal = true;
    },

    hideFilterModal() {
      this.showAdvancedModal = false;
    },

    // Tags filtering modal selected
    handleCloseTag(event) {
      const label = event.srcElement.previousSibling.data.trim();
      const indexToDelete = this.currentFilter.findIndex(
        (element) => element.label.trim() === label
      );
      if (indexToDelete !== -1) {
        const role = event.currentTarget.parentNode.getAttribute("role");
        // Remove from filter
        this.filter = {
          ...this.filter,
          [ROLE_TAG[role]]:
            ROLE_TAG[role] === "more"
              ? clearMoreTagFilterValues(this.filter[ROLE_TAG[role]], label)
              : ROLE_TAG[role] === "safe"
              ? getStatus(this.filter.safe)
              : ROLE_TAG[role] === "reboot"
              ? getStatus(this.filter.reboot)
              : ROLE_TAG[role] === "status"
              ? getStatus(this.filter.status)
              : this.filter[ROLE_TAG[role]].filter(
                  (element) => element !== label
                ),
        };

        // Remove from tags
        this.$emit("close-filter", this.currentFilter[indexToDelete]);
        this.currentFilter.splice(indexToDelete, 1);
      }
    },
    handleApplyFilter(data) {
      this.filter = { ...data };
      this.currentFilter = filterTroubleshootTags(this.filter);
      this.$emit("apply-filter", data);
      this.hideFilterModal();
    },
    /**
     * methods for table selection
     *
     */
    toggleSelection() {
		if (!this.$refs.myTable){
			this.handleSelectionChange([])
			this.$emit("unselect-all");
		}
		else{
			this.$refs.myTable.clearSelection();
			if (this.isAllButtonSelected){
				this.$emit("unselect-all");
			}
			else {
				let rows = Math.min(this.tableData.length, this.queryCount, this.bulkSize);
				for (let row = 0; row < rows; row++)
					this.$refs.myTable.toggleRowSelection(this.tableData[row], true);
				this.$emit("select-all");
			}
		}
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.rows_selected = val.length;
    },

    handleTableCheck(val) {
    },
    clearFilter() {
      this.currentFilter = [];
      (this.filter = {
        ...DEFAULT_FILTER,
        period_options: this.filter.period_options,
      }),
        this.$emit("clear-filter");
    },
    handleResetMoreFilter(router) {
      this.filter.more = {
        ...DEFAULT_FILTER.more,
        router: router,
      };
    },
    handleApplyChanges() {
      const selection = [];
      this.multipleSelection &&
        this.multipleSelection.map(
          (element) => element && selection.push(element.router_id)
        );
      this.$emit("apply-changes", selection);
    },
  },
};
</script>

<style lang="scss">
.router-card-body {
  padding: 0 20px;
}
.select-table {
  .click-drag {
    cursor: grabbing;
    display: flex;
    flex-direction: column;
  }
  .new-button {
    color: white;
    border: none;
    background-color: #f96332;
    margin: 0.25em 0;
  }
  .new-button:hover {
    color: black;
    border: none;
    background-color: #fdb096;
    margin: 0.25em 0;
  }
}
.select-table-card {
  transition: opacity 0.2s ease;

  &_updating {
    opacity: 0.6;
  }

  &__perpage-select {
    width: 70px;
    input {
      margin: 0 !important;
    }
  }
  &__pagination {
    &-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (min-width: 576px) {
        justify-content: space-between;
      }
    }
    &-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 576px) {
        display: block;
        width: auto;
      }
    }
  }

  .search-text {
    margin: 15px 15px;
  }

  .partial_matches_alert {
    font-size: 0.6875rem;
  }
  .el-table .cell {
    word-break: normal;
    padding-top: 1px;
    padding-bottom: 1px;
  }
}
span.countdown-timer {
  margin-left: 1em;
}

.hr-grey {
  height: 1px;
  background-color: #e3e3e3;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
  color: black;
  margin: 0;
}

// Checkbox styles
.el-checkbox {
  margin: 0;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #fb6340;
  border-color: #fb6340;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fb6340;
  border-color: #fb6340;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #f78a71;
  border-color: #f78a71;
}

.el-checkbox__input.is-disabled.is-indeterminate .el-checkbox__inner {
  background-color: #f78a71;
  border-color: #f78a71;
}

.el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 2px solid #888;
  border-radius: 3px;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #606266;
}

.el-checkbox__inner:hover {
  border-color: #fb6340;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fb6340;
}

.el-table th > .cell {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-right: 14px;
  width: 100%;
}

.el-table .not-event {
  pointer-events: "none";
}
</style>
