<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-row>
      <b-col cols="12" xl="6">
        <server-paginated-list
          key="router-server"
          v-model="router_list"
          :title="t('ROUTER_ALLOWED_LIST')"
          :subtitle="this.t('router_allowed_default_help_text')"
          :permissions="permissions"
          :columns="columns"
          search_field="value"
          action="allow"
          :querys="router_querys"
          @get-list="(data) => getList({...data, query: data.query || query, list: 'Router'})"
          @update_list="(data) => setList({...data, query: data.query || query, list: 'Router'})"
        />
      </b-col>
      <b-col cols="12" xl="6">
        <server-paginated-list
          key="dns-server"
          v-model="dns_list"
          :title="t('DNS_ALLOWED_LIST')"
          :subtitle="this.t('dns_allowed_default_help_text')"
          :permissions="permissions"
          :columns="columns"
          search_field="value"
          action="allow"
          :querys="dns_querys"
          @get-list="(data) => getList({...data, query: data.query || query, list: 'DNS'})"
          @update_list="(data) => setList({...data, query: data.query || query, list: 'DNS'})"
          :isDNS="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ServerPaginatedList from "@/components/ServerPaginatedList";
import { mapActions } from "vuex";
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "rs-allowed-lists",
  components: {
    ServerPaginatedList,
    PageTitleCard
  },
  data() {
    return {
      router_querys: {},
      dns_querys: {},
      router_list: [],
      dns_list: [],
      permissions: [
        { actions: ['POST'], path: "/subsystems/rs/router-protection/router-allowed-list/modify" },
        { actions: ['POST'], path: "/subsystems/rs/router-protection/dns-allowed-list/modify" }
      ],
      columns: [
        {
          prop: "index",
          minWidth: 30,
        },
        {
          prop: "value",
          minWidth: 100,
        },
      ],
    }
  },
  async created() {
    this.getList({ query: this.query, list: 'Router' })
    this.getList({ query: this.query, list: 'DNS' })
  },
  methods: {
    ...mapActions("routerSecure/allowedLists", [
      "getRouterAllowed",
      "getDNSAllowed",
      "setRouterAllowed",
      "setDNSAllowed",
    ]),
    async getList(data) {
        if (data.list === 'Router' && data.query) {
          try{
            if (!data.query.limit && this.router_querys) {
              data.query.limit = this.router_querys.query.limit
            }
            const router_res = await this.getRouterAllowed({ query: data.query });
            this.router_list = router_res.items;
            this.router_querys = { query: router_res.query, self_query: router_res.self };
          } catch(error){}
        } else if (data.list === 'DNS' && data.query) {
          try{
            if (!data.query.limit && this.dns_querys) {
              data.query.limit = this.dns_querys.query.limit
            }
            const dns_res = await this.getDNSAllowed({ query: data.query })
            this.dns_list = dns_res.items;
            this.dns_querys = { query: dns_res.query, self_query: dns_res.self };
          } catch(error){}
        }
    },
    async setList(data) {
      if (data.list === 'Router' && data.query) {
        await this.setRouterAllowed({ action: data.action, item: data.item, query: data.query })
      } else if (data.list === 'DNS' && data.query) {
        await this.setDNSAllowed({ action: data.action, item: data.item, query: data.query })
      }
      this.getList({ query: data.query, list: data.list })
    }
  },
};
</script>
<style>

</style>
