<template>
  <div>
    <b-card class="card-user" no-body>
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-three"></div>
        <div class="block block-four"></div>
      </div>

      <b-card-body style="margin-top: -30px; min-height: 75px">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <h5 class="title">
                  <i class="mr-2"><svgicon class="icon" icon="item-status" /></i
                  >{{ t("Authentication") }}
                </h5>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "rs_authentication",
  components: {

  },
  data() {
    return {
      
    };
  },
  async mounted() {
    
  },
  computed: {
		
  },
  methods: {
		
  },
};
</script>
<style>

</style>
