<template>
  <div>
    <page-title-card
      :page-title="t('Agent Management')"
    >
      <template #button>
        <n-button
          type="primary"
          size="md"
          round
          block
          native-type="submit"
          @click.native="newAgent"
          :visible_for_permissions='[{actions: ["POST"], path: "/subsystem/rs/load_router_agent_package"}]'
        >
          <i><svgicon class="icon" icon="icon-new"/></i>
          {{ t('Upload new agent') }}
        </n-button>
      </template>
    </page-title-card>
    <b-card class="card-user" no-body>
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-three"></div>
        <div class="block block-four"></div>
      </div>

      <b-card-body style="margin-top: -30px; min-height: 75px">

      </b-card-body>

      <b-card-body style="padding: 0px;">
        <paginated-table :rows='agents'
                        :columns='columns'
                        :actions="actions"
                        :updating="updating"
                        :search="['agent_version_id']"
                        :searchText="this.t('Agent Version')"
                        :serverpagination="null"
                        :fuzzySearchParams="{'threshold' : 0 }"
                        @editAgent="handleEditAgent"  @removeAgent="handleRemoveAgent" @cloneAgent="handleCloneAgent"/>
    </b-card-body>

    <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
            <i class="now-ui-icons arrows-1_refresh-69" />
            {{ t('Update now') }}
        </div>
        <div v-else>
            <i class="now-ui-icons loader_refresh spin"/>
            {{ t('Updating...') }}
        </div>
    </template>
    </b-card>

    <b-modal id="modal-new" ref="modal" :title="modalTitle" @ok="handleOkUpdateAgent" @hidden="resetNewAgent"  hide-footer >      
      <b-container fuild style="width: 100%; padding: 0px;">
        <!--<b-row>
          <b-col cols="10" v-if="newPackage">
            {{t('Agent File')}}:
          </b-col>
        </b-row>-->

        <b-row class="mt-3" >
          <b-col sm="6" v-if="newPackage">
              <label class="prefix">{{t('Agent File')}}</label>
          </b-col>
        </b-row>

        <b-row  v-if="newPackage">
          <b-col cols="12">
              <b-form-file v-if="!packageUploaded" accept=".tgz, .tar" v-model="file"  ref="file-input" class="mb-2" placeholder="" browse-text="..."></b-form-file>
              <div class="space_between_buttons" v-else>{{file.name}}</div>
              <p class="errorMessage pl-0" v-if="errorFile==true && !file">{{ getError('UploadFile') }}</p>
              <p class="errorMessage pl-0" v-if="error_uploading!=''">{{ t(error_uploading) }}</p>
          </b-col>
        </b-row>

        <b-row class="my-2" v-if="newPackage && packageUploaded">
          <b-col sm="12">
              <div class="hr-grey"/>
          </b-col>
        </b-row>

        
        <template v-if="packageUploaded || !newPackage">
          <b-row class="mt-3" >
            <b-col sm="6">
                <label class="prefix">{{t('Agent Version')}}</label>
            </b-col>
          </b-row>
          <b-row align-v="center">
            <b-col sm="6" class="space_between_buttons" >
              <div class="space_left" >
                {{  agent.agent_version_id }}
              </div>
            </b-col>
          </b-row>
          <b-row class="my-2" >
            <b-col sm="12">
                <div class="hr-grey"/>
            </b-col>
          </b-row>
          <b-row class="mt-3" >
            <b-col sm="6">
                <label class="prefix">{{t('Router Model')}}</label>
            </b-col>
          </b-row>
          
          <b-row align-v="center">
            <b-col sm="10" class="space_between_buttons" >
              <form @submit.prevent="save" class="form-group has-label" v-if="clonePackage">                 
              <fg-input  v-model="agent.router_model" v-if="clonePackage"
                                    id="name"                                    
                                    name="name"
                                    label=""
                                    :placeholder="this.t('Router Model')"
                                    autocapitalize="no"
                                    v-validate="{ required: true, max: 128, regex: routerModel }"
                                    :error="getError('name')"
                                    class="no-border no-height input-group">
              </fg-input>
              </form>
                
              <div class="space_left" v-else>
                {{  agent.router_model }}
              </div>
            </b-col>
          </b-row>
          
          <b-row class="my-2" >
            <b-col sm="12">
                <div class="hr-grey"/>
            </b-col>
          </b-row>

          <b-row class="mt-3" v-if="packageUploaded">
            <b-col sm="8">
                <label class="prefix">{{t('Router Firmware Versions')}}</label>                
            </b-col>
          </b-row>

          <b-row align-v="center" v-if="packageUploaded">
            <b-col sm="6" class="space_between_buttons" >
              <div class="space_left" v-if="agent.firmware_versions.length <= 0" >
                {{ t('undefined') }}
              </div>

              <div class="space_left" v-else v-for="fw in agent.firmware_versions" >
                <div>{{ fw  }}</div>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-2" v-if="packageUploaded">
            <b-col sm="12">
                <div class="hr-grey"/>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12"  style="text-align:center"  >
              <p :class="[{green: fileUploaded == 'uploaded_ok'}, {red: fileUploaded != 'uploaded_ok'}]"   >{{ t(txtUploaded) }}</p>
            </b-col>
          </b-row>

        </template>

        <b-row class="mt-3" v-if="!newPackage">
          <b-col sm="12">
              <label class="prefix">{{t('Router Firmware Versions')}}</label>
              <label class="clonepack" v-if="clonePackage" >({{t('optional')}})</label>
          </b-col>
        </b-row>

        <b-row class="mt-3" v-if="!newPackage">
          <b-col sm="12"  class="space_left">
            <autocomplete-tag-input
              inputType="routerfirmware"
              :value='local_firmware_versions'
              @change="handleChange($event)"
              @close="handleClose($event)"
              :placeholder="t('Firmware Version')"
              :showdropdown='showDropDown'
              tagClass="default-tag"
              :localClose="localClose"
            ></autocomplete-tag-input>
            <label class="clonepack" v-if="clonePackage" >{{t('If no firmware version selected, it wil be applied to any version')}}</label>
            <p v-if='errorUpdateMessage' class="errorMessage">{{t(errorUpdateMessage)}}</p>
          </b-col>
        </b-row>

        <b-row class="mt-3" v-if="newPackage">
          <b-col cols="8" style="margin-top: 5px;" class="space_left" >
            <n-button :disabled="updating" native-type="submit" type="primary"  v-if="!fileUploaded" size="md"  @click.native="handleNewAgent">
              {{ t('Load') }}
            </n-button>
            <n-button native-type="submit" type="primary" v-else-if="isDefaultButtonVisible" size="md"  @click.native="handleSaveAsDefault">
              {{ t('Save as Default') }}
            </n-button>
          </b-col>

          <b-col cols="4" style="margin-top: 5px;text-align:right" class="space_left" >
            <n-button native-type="submit" type="primary" size="md"  @click.native="handleCancel">
              <span v-if="!fileUploaded">{{ t('Cancel') }}</span>
              <span v-else>{{ t('Close') }}</span>
            </n-button>
          </b-col>
        </b-row>

        <b-row class="mt-3" v-else>
          <!--<b-col cols="8" style="margin-top: 5px;" >
            <n-button native-type="submit" type="primary" size="md" v-if="isDefaultButtonVisible" @click.native="handleSaveAsDefault">
              {{ t('Save as Default') }}
            </n-button>
          </b-col>-->
          <b-col cols="4" style="margin-top: 5px;" class="space_left" >
            <n-button native-type="submit" type="primary" size="md"  @click.native="handleCancel">
              {{ t('Close') }}
            </n-button>
          </b-col>

          <b-col cols="8" style="margin-top: 5px;text-align:right" >
            <n-button native-type="submit" type="primary" size="md" v-if="clonePackage" @click.native="handleOkCloneAgent">
              {{ t('Save') }}
            </n-button>
            <n-button native-type="submit" type="primary" size="md" v-else @click.native="handleOkUpdateAgent">
              {{ t('Save') }}
            </n-button>
          </b-col>
        </b-row>
      </b-container>
      
    </b-modal>

    <b-modal id="agent-msg" @ok="hideAgentMessage" @hide="hideAgentMessage">
		  {{ t(msg) }}
      <template #modal-footer>
		  	<div class="button-container">
		  		<button type="button" class="btn btn-primary btn-block" @click="hideAgentMessage"> {{t('Close')}} </button>
		  	</div>
		  </template>
    </b-modal>
  </div>
</template>

<script>
import { PaginatedTable } from '@/components';
import { Switch } from '@/components';
import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
import {  DateTimeFormatter, ListFormatter } from '@/components/Tables/Formatters'
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";
import { routerModelRegEx } from "@/util/regexConsts";

export default {
  name: "rs_agent_management",

  components: {
    PaginatedTable,
    [Switch.name]: Switch,
    'autocomplete-tag-input': AutocompleteTagInput,
    PageTitleCard
  },
  data() {
    return {
      agents_list: new Map(),
      agent: {
        agent_package_id : '',
        agent_version_id : '',
        router_model : '',
        firmware_versions : [],
        upload_time : '',
        upload_timef : ''
      },
      src_firmware_versions: [],
      local_firmware_versions: [],
      newPackage : false,
      clonePackage : false,
      packageUploaded : false,
      errorFile : false,
      fileUploaded : null,
      txtUploaded : '',
      modalTitle : '',
      file : null,
      updating: false,
      showDropDown : false,
      error_uploading : '',
      localClose : true,
      msg : '',
      errorUpdateMessage : '',      
      columns: [
      {
            prop: 'agent_package_id',
            label: this.t("agent_package_id"),
            minWidth: 130
        },
        {
            prop: 'agent_version_id',
            label: this.t("Agent Version"),
            minWidth: 130
        },
        {
            prop: 'router_model',
            label: this.t('Router Model'),
            minWidth: 130,
        },
        {
            prop: 'firmware_versions',
            label: this.t('Firmware Version'),
            minWidth: 130,
            anyText: this.t('any'),
            formatter: ListFormatter
        },
        {
            prop: 'upload_timef',
            label: this.$t('Date'),
            minWidth: 130,
            formatter: DateTimeFormatter
        }
      ],
      actions: {
        minWidth: 100,
        label: this.$t('Actions'),
        fixed: 'right',
        items: [
            { type: 'secondary', icon: 'ic:baseline-control-point-duplicate', event: 'cloneAgent', action: this.t('Clone'), isIconify: true,
            visible_for_permissions: [{ actions: ["POST"], path: "/subsystem/rs/router-agent-packages/.*" }] },
            { type: 'warning', icon: 'now-ui-icons business_badge', event: 'editAgent', action: this.t('Edit'),
            visible_for_permissions: [{ actions: ["PATCH"], path: "/subsystem/rs/router-agent-packages/.*" }]},
            { type: 'danger',  icon: 'now-ui-icons ui-1_simple-remove', event: 'removeAgent', action: this.t('Remove'),
                confirm: false, visible_for_permissions: [{actions: ["DELETE"], path: "/subsystem/rs/router_agent_packages/.*"}]},

        ]
      },

    };

  },
  async mounted() {
    this.refresh(0)
  },
  computed: {
		agents(){
      const lang = this.$store.getters['settings/getLanguage']
      let res = this.columns.find( item => item.prop === 'firmware_versions' );
      res.anyText =  this.t('any');

      res = this.columns.find( item => item.prop === 'agent_package_id' );
      res.label =  this.t('agent_package_id');
      let ret = [];     
      this.agents_list.forEach( (value, key) => {
          let item = {
            agent_package_id : value.agent_package_id,
            agent_version_id : value.agent_version_id,
            router_model : value.router_model,
            firmware_versions : value.firmware_versions,
            upload_time : value.upload_time,
            upload_timef : this.toLocaleString(this.datetimeFromISO(value.upload_time))
          }
          ret.push(item)
      })
      return ret;
    },
    isDefaultButtonVisible() {
      return this.agent && this.agent.firmware_versions !== undefined && this.agent.firmware_versions.length > 0 && this.agent.firmware_versions[0] !== ''
    },
    routerModel() {
      return routerModelRegEx;
    }
  },
  watch:{
    file(nfile){
      if(nfile != "") this.error_uploading = ''
    },
  },
  methods: {
    getError(fieldName) {
      if(fieldName == "UploadFile")
        return this.t("No file selected");
      else
        return this.errors.first(fieldName);
    },
    handleChange(event) {      
      this.errorUpdateMessage = ''
      if(event.length > 100){
        this.msg = 'max_firmware_limit';
        this.$bvModal.show("agent-msg");
      }
      else
        this.local_firmware_versions = event
    },
    handleClose(tag) {
      this.errorUpdateMessage = ''
      if( this.src_firmware_versions.indexOf(tag) >= 0 ){
        this.$bvModal.msgBoxConfirm(this.t("sure_delete_firmware"), {
          okTitle: this.$t('accept'),
          cancelTitle: this.$t('cancel')
        })
        .then(confirmed => {
            if(confirmed)
              this.local_firmware_versions.splice(this.local_firmware_versions.indexOf(tag), 1);
        })
        .catch()
      } else
        this.local_firmware_versions.splice(this.local_firmware_versions.indexOf(tag), 1);
    },
    newAgent(){
      this.modalTitle = this.t('Upload Router Agent Package')
      this.newPackage = true
      this.clonePackage = false
      this.packageUploaded = false
      this.errorFile = false
      this.fileUploaded = null
      this.txtUploaded = ''
      this.error_uploading = ''
      this.$refs.modal.show()
    },
    resetNewAgent(){
      this.modalTitle = ''
      this.file = null;
      this.agent = {
        agent_version_id : '',
        router_model : '',
        firmware_versions : [],
        upload_time: '',
        upload_timef: '',
        agent_package_id: '',
      },
      this.local_firmware_versions = []
      this.packageUploaded = false
      this.errorFile = false
      this.fileUploaded = null
      this.txtUploaded = ''
      this.error_uploading = ''
      this.errorUpdateMessage = ''
    },
    handleCancel(){
      this.$refs.modal.hide()
    },

    saveAsDefault(){
      this.$store.dispatch('routerSecure/agentManagement/saveDefaultAgent', this.agent ).then( res => {
          this.$store.commit("setSuccess", this.t("Router Agent Package") + " " + this.t("successfully save as default"))
      })
      .catch( err => {
        this.refresh(0);
      })
    },

    handleSaveAsDefault(){
      this.$store.dispatch('routerSecure/agentManagement/checkDefaultRouterModel', this.agent ).then( res => {
        if( res == "1" ){
          this.msg = 'default agent version already exists';
          this.$bvModal.show("agent-msg");
        }
        else
          this.saveAsDefault()
      })
      .catch( err => {
        this.refresh(0);
      })
    },
    hideAgentMessage() {
      this.$bvModal.hide("agent-msg");
    },
    async handleOkUpdateAgent(){      
      this.errorUpdateMessage = ''
      this.agent.firmware_versions = this.local_firmware_versions
      this.$store.dispatch('routerSecure/agentManagement/updateAgent', this.agent ).then( res => {
          this.$store.commit("setSuccess", this.t("Router Agent Package") + " " + this.$t("successfully updated"))
          this.agents_list = res;
          this.$refs.modal.hide()
      })
      .catch( err => {
        this.errorUpdateMessage = 'not_updated'
        this.agent.firmware_versions = _.union(this.agent.firmware_versions, this.src_firmware_versions)
        this.refresh(0);
      })     
    },
    async handleOkCloneAgent(){     
        let isValidForm = await this.$validator.validateAll();
        if (!isValidForm) return
        this.errorUpdateMessage = ''
        this.agent.firmware_versions = this.local_firmware_versions
        this.$store.dispatch('routerSecure/agentManagement/cloneAgent', this.agent ).then( res => {
            this.$store.commit("setSuccess", this.t("Router Agent Package") + " " + this.t("successfully cloned"))
            this.agents_list = res;
            this.$refs.modal.hide()
        })
        .catch( err => {
          this.errorUpdateMessage = this.$store.getters["routerSecure/agentManagement/agentError"]
          this.agent.firmware_versions = _.union(this.agent.firmware_versions, this.src_firmware_versions)
          this.refresh(0);
        })     
    },
    handleNewAgent(){
      let _vm = this
      const file = this.file;
      const reader = new FileReader();

      reader.onload = function( e ) {
        const bfile = btoa(e.target.result);
        _vm.updating = true;

        _vm.$store.dispatch('routerSecure/agentManagement/addAgent', bfile)
        .then(function(res){
          _vm.agent = _.cloneDeep(res)
          _vm.packageUploaded = true

          _vm.agents_list.set(_vm.agent.agent_package_id, _vm.agent);
          _vm.agents_list = new Map(_vm.agents_list);
          _vm.fileUploaded = "uploaded_ok";
          _vm.txtUploaded = _vm.t("File loaded succesfully");
          _vm.error_uploading = ''
          _vm.updating = false;
        })
        .catch(err => {
            _vm.packageUploaded = false
            _vm.fileUploaded = null;
            _vm.txtUploaded = _vm.t("File was not loaded");
            _vm.error_uploading = _vm.$store.getters["routerSecure/agentManagement/agentError"]
            _vm.updating = false;
        });
      }

      if( this.file ){
        this.errorFile = false;
        reader.readAsBinaryString(file);
      } else
        this.errorFile = true;
    },

    async handleEditAgent(data) {
      this.newPackage = false
      this.clonePackage = false
      this.packageUploaded = false
      this.modalTitle = this.t('Edit Router Agent Package')
      this.file = null;
      this.$refs.modal.show()

      this.agent.agent_package_id = data.row.agent_package_id
      this.agent.agent_version_id = data.row.agent_version_id
      this.agent.router_model = data.row.router_model
      this.agent.firmware_versions = data.row.firmware_versions
      this.agent.upload_time = data.row.upload_time
      this.agent.upload_timef = data.row.upload_time
      this.src_firmware_versions = _.cloneDeep(data.row.firmware_versions);
      this.local_firmware_versions = _.cloneDeep(data.row.firmware_versions);
    },

    async handleCloneAgent(data) {
      this.newPackage = false
      this.clonePackage = true
      this.packageUploaded = false
      this.modalTitle = this.t('Clone Router Agent Package')
      this.file = null;
      this.$refs.modal.show()

      this.agent.agent_package_id = data.row.agent_package_id
      this.agent.agent_version_id = data.row.agent_version_id
      this.agent.router_model = ""
      this.agent.firmware_versions = []
      this.agent.upload_time = data.row.upload_time
      this.agent.upload_timef = data.row.upload_time
      this.src_firmware_versions = []//_.cloneDeep(data.row.firmware_versions);
      this.local_firmware_versions = []
    },

    async handleRemoveAgent(data){
      const res = await this.$store.dispatch('routerSecure/agentManagement/checkAgentPackageAsDefault', data.row);
      const msg =  (res == "1" ? this.t('delete_default')
                       : this.t('Are you sure you want to delete this agent package?'))

      this.$bvModal.msgBoxConfirm(msg, {
          okTitle: this.$t('accept'),
          cancelTitle: this.$t('cancel')
      })
      .then(confirmed => {
          if(confirmed) {
              this.$store.dispatch('routerSecure/agentManagement/deleteAgent', data.row.agent_package_id).then( res => {
              this.$store.commit("setSuccess", this.t("Router Agent Package") + " " + this.$t("successfully removed"))
              this.agents_list = res
          })
          .catch( err => this.refresh(0) )
          }
      })
      .catch(() => {
      })
    },

    refresh(wait){      
      let _vm = this
      this.updating = true;
      this.$store.dispatch('routerSecure/agentManagement/getAgents')
      .then( res => {           
          _vm.agents_list = _.cloneDeep(res)
          this.updating = false
      })
      .catch( err => {          
          this.updating = false
      });
    }
  },
};
</script>
<style lang="scss" scoped>

  .prefix {
    margin-left: 0px;
    text-transform: uppercase;
    opacity: 0.7!important;
    margin-bottom: 15px;
  }

  .clonepack {
    margin-left: 5px;   
    opacity: 0.7!important;
    margin-bottom: 15px;
  }

  .space_between_buttons {
        padding-top: 13px;
    }

  .info{
    margin: 15px;
  }

  .red {
    color: red ! important;
  }
  .green {
    color: green ! important;
  }
  .wmargin {
    margin-left: 2em
  }
  .button-container {
	  position: relative;
    left: 40%;
  }

  .space_left {
    margin-left: 0px;
  }
  
</style>
